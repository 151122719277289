import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { MdFavorite } from "react-icons/md";
import { actionType } from "../context/reducer";
import { useStateValue } from "../context/StateProvider";
import { FaStar } from "react-icons/fa";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { WiHumidity } from "react-icons/wi";
import { MdOutlineHeight } from "react-icons/md";
import { TiWeatherSunny } from "react-icons/ti";
import { FaTemperatureHigh } from "react-icons/fa";
import { MdClose } from 'react-icons/md';
import { Link, useNavigate } from "react-router-dom";
// ImageSlider component
const ImageSlider = ({ image }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Show arrows for navigation
    vertical: false, // Horizontal scrolling
  };

  // Repeat the single image three times
  const images = [image, image, image];

  return (
    <Slider {...settings} className="w-full">
      {images.map((img, index) => (
        <div key={index} className="p-2">
          <img
            src={img}
            alt={`Slide ${index}`}
            className="w-full h-auto rounded-lg"
          />
        </div>
      ))}
    </Slider>
  );
};

const CardContainer = ({ flag, item }) => {
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [{ cartItems }, dispatch] = useStateValue();

  const navigate = useNavigate();

  const addtocart = () => {
    // Check if item already exists in cart
    const updatedItems = cartItems.some(cartItem => cartItem.id === item.id) ?
      cartItems.map(cartItem =>
        cartItem.id === item.id ? { ...cartItem, qty: cartItem.qty + 1 } : cartItem
      ) :
      [...cartItems, { ...item, qty: 1 }];

    localStorage.setItem("cartItems", JSON.stringify(updatedItems));
    dispatch({
      type: actionType.SET_CART_ITEMS,
      cartItems: updatedItems,
    });
  };

  // const addtocart = (item) => {
  //   localStorage.setItem("cartItems", JSON.stringify(items));
  //   dispatch({
  //     type: actionType.SET_CART_ITEMS,
  //     cartItems: items,
  //   });
  // };

  // useEffect(() => {
  //   addtocart();
  // }, [items]);

  const handleNavigation = () => {
    navigate('/plantitem', { state: { item } });
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const sliderImage = item?.image;

  return (
    <>
      <div
        key={item?.id}
        className={`w-275 min-w-[275px] md:w-300 md:min-w-[300px] bg-cardOverlay rounded-lg lg:my-12 my-8 backdrop-blur-lg hover:drop-shadow-lg py-2 px-4 flex flex-col items-center justify-evenly relative gap-3 ${flag ? "h-[300px]" : "h-[200px]"
          }`}
      >
        <div className="w-full flex items-center justify-between">
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="w-40 h-40 -mt-14 -ml-4 drop-shadow-2xl"
          >
            {/* <Link to={{
              pathname: "/plantitem",
              state: { item } // Pass the item data here
            }}> */}
              <button onClick={handleNavigation}>
                <img
                  src={item?.image}
                  alt={item?.plantName}
                  className="h-full w-full object-contain"
                />
            </button>
            {/* </Link> */}
          </motion.div>
          <motion.div
            whileTap={{ scale: 0.75 }}
            className="w-8 h-8 rounded-full bg-green-600 flex items-center justify-center cursor-pointer hover:shadow-md"
            onClick={() => setItems([...cartItems, item])}
          >
            <MdFavorite className="text-white" onClick={() => addtocart()} />
          </motion.div>
        </div>
        <div className="w-full flex flex-col items-end justify-end -mt-4 gap-2">
          <p className="text-textColor font-semibold text-base md:text-lg">
            {item?.plantName}
          </p>
          {flag && (
            <div className="flex items-end flex-col justify-center">
              <p className="text-sm text-green-500 mb-1 capitalize">
                {item.category}
              </p>
              <p className="text-[.8rem] text-gray-500 text-right">
                {item?.description}
              </p>
            </div>
          )}
          <div className="w-full flex items-center gap-8 justify-between">
            <p className="mt-1 text-sm text-gray-500 flex items-center justify-center gap-1">
              {item?.rating} <FaStar className="text-sm text-yellow-400" />
            </p>
            <button
              className="bg-cartNumBg w-auto min-w-[94px] h-10 p-4 cursor-pointer rounded-lg drop-shadow-md flex flex-col gap-3 items-center justify-center text-white hover:bg-green-600 hover:text-black"
              onClick={handleButtonClick}
            >
              Read more
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
          <div className="relative bg-gradient-to-b from-green-600 via-green-400 to-green-300 text-white w-full max-w-lg lg:max-w-2xl h-auto max-h-[95vh] overflow-y-auto p-6 rounded-lg shadow-lg">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 p-2 rounded hover:bg-green-700 hover:text-white z-20"
              onClick={closeModal}
              aria-label="Close"
              style={{ zIndex: 1000 }}
            >
              <MdClose className="text-green text-3xl cursor-pointer" />
            </button>

            {/* Image Slider Section */}
            <div className="mb-6 ">
              <h2 className="text-2xl font-bold mb-4">Plant Details</h2>
              {sliderImage && (
                // remove  overflow-hidden to add image navigation
                <div className="w-full overflow-hidden">

                  <ImageSlider image={sliderImage} />
                </div>
              )}
            </div>

            {/* Plant Data Section */}
            <div className="relative p-4 rounded-lg mt-[-60px] z-10" style={{ backgroundColor: '#f5f3f3', color: 'black' }}>
              <p className="mb-4"><strong>Name:</strong> {item?.plantName}</p>
              <p className="mb-4"><strong>Scientific Name:</strong> {item?.plantScientificName}</p>
              <p className="mb-4"><strong>Synonyms:</strong> {item?.Synonyms || item?.commonName}</p>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <p className="flex items-center"><WiHumidity className="text-green-600 w-6 h-6 mr-2" /> <span className="ml-2">{item?.careTips?.humidityNumber}</span></p>
                <p className="flex items-center"><FaTemperatureHigh className="text-green-600 w-6 h-6 mr-2" /> <small className="ml-2">{item?.careTips?.temperature}</small></p>
                <p className="flex items-center"><TiWeatherSunny className="text-green-600 w-6 h-6 mr-2" /><span className="ml-2">{item?.careTips?.light}</span> </p>
                {/* {item?.height} */}
                <p className="flex items-center"><MdOutlineHeight className="text-green-600 w-6 h-6 mr-2" /><span className="ml-2">2 feet</span> </p>
              </div>
              <p className="mb-4"><strong>Note :</strong> {item?.note}</p>
              <p className="mb-4"><strong>Best For:</strong> {item?.bestFor}</p>
              <p className="mb-4"><strong>Highlight:</strong> {item?.highlight}</p>
              <p className="mb-4"><strong>Description:</strong> {item?.description}</p>
              <p className="mb-4"><strong>Long Description:</strong> {item?.longDescription}</p>

              {/* Display care tips */}
              {item?.careTips && (
                <div className="mb-4">
                  <h3 className="text-xl font-semibold mb-2">Care Tips</h3>
                  <p><strong>Light:</strong> {item.careTips.light}</p>
                  <p><strong>Water:</strong> {item.careTips.water}</p>
                  <p><strong>Temperature:</strong> {item.careTips.temperature}</p>
                  <p><strong>Humidity:</strong> {item.careTips.humidity}</p>
                  {/* <p><strong>Humidity Level:</strong> {item.careTips.humidityno}</p> */}
                  <p><strong>Toxicity:</strong> {item.careTips.toxicity}</p>
                  <p><strong>Soil:</strong> {item.careTips.soil}</p>
                  <ul className="list-disc ml-5">
                    {item.tips && item.tips.map((tip, index) => (
                      <li key={index}>{tip}</li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Display modal close button */}
              <button
                className="mt-4 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 hover:text-black"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}



    </>
  );
};

export default CardContainer;
