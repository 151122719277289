import React from "react";

const ContactUs = () => {
  return (

    <section className=" text-gray-800 ">
      <p className="text-3xl font-semibold mb-4">How to Get in Touch
      </p>
      <div className="mx-auto flex max-w-md items-center xl:items-center flex-col rounded-lg sm:max-w-screen-xl xl:flex-row  ">

        <p className="text-justify  xl:max-w-[30%]   mb-6 mx-8">
          If you are interested in partnering with us or would like to learn more about how we can support your business, please reach out to us. We would be delighted to discuss your project requirements and explore how we can collaborate to achieve your goals.
          <p className="mt-5"> Let’s build something great together! Whether you’ re looking to develop a new
            technology solution, enhance your existing systems, or explore digital

            transformation, we are here to help. Contact us today to start the conversation.</p>
        </p>
        <div className="border border-gray-100 shadow-gray-500/20 mt-8 mb-8  bg-white shadow-sm sm:rounded-lg sm:shadow-lg lg:mt-0">
          <div className="relative border-b border-gray-300 p-4  sm:px-8">
            <h3 className="mb-1 inline-block text-3xl font-medium">
              <span className="mr-4">Contact Form</span>

            </h3>
            <p className="text-gray-600">
              Please fill out the form below, and we will get back to you as soon as possible.</p>
          </div>
          <div className="p-4 flex flex-col mt-2 ">
            <div className="flex  flex-wrap justify-between gap-3">
              <input
                id="name"
                type="text"
                className="mt-2 sm:w-[47%]  w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-green-600  focus:outline-none hover:border-green-600 "
                placeholder="Enter Your Name"
              />
              <input
                id="email"
                type="email"
                className="peer mt-2 sm:w-[47%] w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-green-600  focus:outline-none hover:border-green-600 "
                placeholder="Enter Your Email"
              />
              <div className="peer-invalid:block mt-1 hidden text-left text-xs text-rose-600">
                Email format Incorrect. Please complete the email
              </div>
            </div>
            <div className="flex  flex-wrap gap-3 justify-between mt-2">
              <input
                id="phone"
                type="number"
                className="mt-2 w-full sm:w-[47%] resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-green-600  focus:outline-none hover:border-green-600 "
                placeholder="Enter Your Phone Number"

              />
              <input
                id="CompanyName"
                type="text"
                className="mt-2 w-full sm:w-[47%] resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-green-600  focus:outline-none hover:border-green-600 "
                placeholder="Enter your Company Name"
              />
            </div>

            <textarea
              id="Message"
              placeholder="Message"
              className="mb-8 mt-4 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-green-600  focus:outline-none hover:border-green-600 "
            />
            <button className="w-full rounded-lg border border-green-600  bg-green-600  p-3 text-center font-medium text-white outline-none transition focus:ring hover:border-blue-700 hover:bg-blue-600 hover:text-white">
              Send
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;

