import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 6,
    cat: "indoor",
}

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        loadingFilter: (state, action) => {
            if (action.payload === "indoor") {
                state.cat = action.payload;
                state.value = 6;
            }
            else if (action.payload === "outdoor") {
                state.cat = action.payload;
                state.value = 6;
            }
            else if (action.payload === "flowering") {
                state.cat = action.payload;
                state.value = 6;
            }
            else if (action.payload === "hanging_plant") {
                state.cat = action.payload;
                state.value = 6;
            }
            else if (action.payload === "pet-friendly") {
                state.cat = action.payload;
                state.value = 6;
            }
            else if (action.payload === "XL_floor_standing_plant") {
                state.cat = action.payload;
                state.value = 6;
            }
            else if (action.payload === "office_plant") {
                state.cat = action.payload;
                state.value = 6;
            }
            else if (action.payload === "mossballs") {
                state.cat = action.payload;
                state.value = 6;
            }
        },
        loader: (state, action) => {
            if ( state.cat === "indoor") {  
                // console.log("inside indoor****************");
                state.value = state.value + 6;
            }
            else if(state.cat === "outdoor"){
                console.log(state.value); 
                // state.value = 6;
                state.value = state.value + 6;
            }
            else if(state.cat === "flowering"){
                state.value = state.value + 6;
            }
            else if(state.cat === "hanging_plant"){
                state.value = state.value + 6;
            }
            else if(state.cat === "pet-friendly"){
                state.value = state.value + 6;
            }
            else if(state.cat === "XL_floor_standing_plant"){
                state.value = state.value + 6;
            }
            else if(state.cat === "office_plant"){
                state.value = state.value + 6;
            }
            else if(state.cat === "mossballs"){
                state.value = state.value + 6;
            }  
        }

    },
})

export const { loadingFilter,loader } = categorySlice.actions

export default categorySlice.reducer