import React, { useState } from "react";
import plantData from '../Assets/data'; // Adjust the path to your plant data file
import CardContainer from './CardContainer'; // Adjust the path to your CardDetails component
import NotFound from "../img/NotFound.svg"; // Adjust the path if necessary
import CartContainer from './CartContainer';
import { useStateValue } from "../context/StateProvider";
import { Link } from "react-router-dom";

const SearchContainer = () => {
  const [search, setSearch] = useState('');
  const [{ plantItems, cartShow }, dispatch] = useStateValue();

  const filteredData = plantData.filter((item) => {
    return search.toLowerCase() === ''
      ? false
      : item.plantName?.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div className="container mx-auto p-4">
      <div className="my-3">
        <input
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Plants"
          className="w-full px-4 py-2 border border-gray-300 rounded-md"
        />
      </div>
      {filteredData.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredData.map((item, index) => (
            <CardContainer
              key={index}
              item={item}
            />
          ))}
        </div>
      ) : (
        <div className="w-full flex flex-col items-center justify-center">
          <img src={NotFound} alt="Not Found" className="h-340" />
          <p className="text-xl text-headingColor font-semibold">
            Plant Not Available
          </p>
        </div>
      )}
      {cartShow && <CartContainer />}
      <button
        type="button"
        className="bg-gradient-to-br from-green-400 to-green-600  px-4 py-2 rounded-lg
      transition-all ease-in-out duration-100 w-full md:w-auto hover:shadow-lg"
      >
        <Link to={"/home"}> Go Back</Link>
      </button>
    </div>
  );
};

export default SearchContainer;
