import React from 'react';

const FooterWithCopyWrite = () => {
  return (
    <div className="bg-gray-100">
        <hr/>
      <div className="mx-auto flex max-w-screen-xl flex-col gap-y-4 px-4 py-3 text-center text-gray-500 sm:flex-row sm:justify-between sm:text-left">
        <div>© 2024 Plantifo  | All Rights Reserved</div>
        <div>
          <a className="" href="#">Privacy Policy</a>
          <span> | </span>
          <a className="" href="#">Terms of Service</a>
        </div>
      </div>
    </div>
  );
};

export default FooterWithCopyWrite;
