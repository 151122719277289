import React, { useEffect } from "react";
import ContactUs from "./ContactUs";
import CartContainer from './CartContainer';
import { useStateValue, } from "../context/StateProvider";
function AboutUsContainer() {
  const [{ plantItems, cartShow }, dispatch] = useStateValue();
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <div className="container mx-auto p-4">
      <div className="mb-8">
        <p className="text-5xl font-bold text-green-600 text-center">
          About Us
        </p>
      </div>
      <div className="mb-8">
        <p className="text-3xl font-semibold mb-4">Introduction</p>
        <div className="flex flex-col md:flex-row items-center">
          <p className="max-w-[656px] mb-4 md:mb-0 md:mr-4 ">
            Welcome to Plantifo , your one-stop solution for connecting
            nurseries, plant sellers, customers, and suppliers across India.
            Ourmission is to revolutionize the plant industry by leveraging
            technology to improve sales, organize inventory, and enhance
            customer engagement. Whether you're a nursery owner looking to
            streamline your operations, a plant enthusiast seeking detailed care
            information, or a supplier aiming to reach a wider audience,
            Plantifo  is here to make your plant journey easier and more
            enjoyable.
          </p>
          <img
            src="path-to-your-image.jpg"
            alt="Introduction"
            className="w-full md:w-1/2 rounded-lg shadow-lg"
          />
        </div>
      </div>
      <div className="mb-8">
        <p className="text-3xl font-semibold mb-4">Our Story</p>
        <p className="">
          Plantifo  was born out of a passion for plants and a vision to create a
          vibrant community of plant enthusiasts. Our founders, Saurabh Dhumane
          and the team Kunal Khelkar, realized the need for a platform that
          could bring together nurseries, customers, and suppliers in a seamless
          and efficient manner. With years of experience in the gardening
          industry, they embarked on this journey to make Plantifo  a reality.
        </p>
      </div>

      <div className=" flex flex-row flex-wrap gap-2 justify-center ">
        <div className="border-black border-2 px-4 pt-7 pb-8 sm:w-[49%]  ">
          <p className="mb-3 font-bold uppercase ">Mission</p>
          <p className="">
            To connect nurseries, plant sellers, customers, and suppliers
            through a comprehensive network, fostering growth and sustainability
            in the plant industry.
          </p>
        </div>
        <div className="border-black   border-2 px-4 pt-7 pb-8 sm:w-[49%] ">
          <p className="mb-3 font-bold uppercase ">Vision</p>
          <p className="">
            To be the leading platform for plant enthusiasts, driving
            innovationand community engagement while promoting sustainable
            gardening practices.
          </p>
        </div>
      </div>

      <div className="  my-5">
        <p className="text-3xl font-semibold ">Features</p>
        <div className="sm:col-gap-12 row-gap-12 md:ga  grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 ">
        {/* //////////////// */}
        {/* <div className="relative md:border-b-2 border-green-600 md:shadow-lg m-2 md:p-8 lg:p-12 group hover:overflow-hidden">
            <div className="absolute inset-0 bg-green-600 transform -translate-x-full group-hover:translate-x-0 transition-transform duration-500 ease-in-out"></div>
            <div className="relative z-10">
              <h3 className="text-xl font-bold text-slate-800 group-hover:text-white transition-colors duration-500">Network Creation</h3>
              <p className="sm:mt-5 text-base text-gray-700 group-hover:text-white transition-colors duration-500">
                Connect with other nurseries and plant sellers across India to share inventory, fulfill orders, and exchange knowledge.
              </p>
            </div>
          </div> */}
        {/* ///////////////////////////////////// */}
          <div className="md:border-b-2 border-green-600  md:shadow-lg m-2 md:p-8 lg:p-12 transition-all duration-300 hover:bg-green-500">
            <h3 className=" text-xl font-bold text-slate-800">
              Network Creation
            </h3>
            <p className="sm:mt-5 text-base text-gray-700">
              Connect with other nurseries and plant sellers across India to
              share inventory, fulfill orders, and exchange knowledge.
            </p>
          </div>
          <div className="md:border-b-2 border-green-600  md:shadow-lg m-2 md:p-8 lg:p-12 transition-all duration-300 hover:bg-green-500">
            <h3 className=" text-xl font-bold text-slate-800">
              Inventory Management
            </h3>
            <p className="sm:mt-5 text-base text-gray-700">
              Organize and manage inventory efficiently with real-time updates
              and notifications.
            </p>
          </div>
          <div className="md:border-b-2 border-green-600  md:shadow-lg m-2 md:p-8 lg:p-12 transition-all duration-300 hover:bg-green-500">
            <h3 className=" text-xl font-bold text-slate-800">Advertising</h3>
            <p className="sm:mt-5 text-base text-gray-700">
              Run targeted ads within the app to reach potential customers in
              the local area and beyond.
            </p>
          </div>
          <div className="md:border-b-2 border-green-600  md:shadow-lg m-2 md:p-8 lg:p-12 transition-all duration-300 hover:bg-green-500">
            <h3 className=" text-xl font-bold text-slate-800">Analytics</h3>
            <p className="sm:mt-5 text-base text-gray-700">
              Access detailed analytics and insights on sales, customer
              demographics, and market trends.
            </p>
          </div>
          <div className="md:border-b-2 border-green-600  md:shadow-lg m-2 md:p-8 lg:p-12 transition-all duration-300 hover:bg-green-500">
            <h3 className=" text-xl font-bold text-slate-800">
              Custom Profiles
            </h3>
            <p className="sm:mt-5 text-base text-gray-700">
              Create detailed profiles showcasing available plants, services,
              and contact information.
            </p>
          </div>
          <div className="md:border-b-2 border-green-600  md:shadow-lg m-2 md:p-8 lg:p-12 transition-all duration-300 hover:bg-green-500">
            <h3 className=" text-xl font-bold text-slate-800">
              Chat and Contact Sharing
            </h3>
            <p className="sm:mt-5 text-base text-gray-700">
              Communicate with other nursery owners, suppliers, and customers
              through an integrated chat feature.
            </p>
          </div>
        </div>
      </div>

      <div className="my-8">
        <p className="text-3xl font-semibold mb-4">Meet the Team</p>
        <div className="flex flex-col md:flex-row items-center">
          <img
            src="path-to-your-image.jpg"
            alt="Saurabh Dhumane"
            className="w-full md:w-1/2 rounded-lg shadow-lg mr-2"
          />
          <div className="max-w-[656px] mb-4 md:mb-0 md:mr-4 text-justify ">
            <h1 className=" font-semibold text-xl mb-1">
              Saurabh Dhumane - Founder & CEO MindScape Technologies
            </h1>
            <p>
              He is the visionary Founder & CEO of MindScape Technologies, a
              leading IT solutions provider specializing in innovative
              technology and digital transformation. With extensive experience
              in technology development and project management, Saurabh is
              committed to delivering cutting-edge solutions that drive business
              success. Under his leadership, MindScape Technologies has gained a
              reputation for excellence in software development and IT
              consulting. Saurabh's passion for technology and entrepreneurship
              inspired the creation of Plantifo , aiming to revolutionize the
              plant industry through advanced digital solutions. Learn more
              about MindScape Technologies at <a className="text-green-600" href="https://mindscapetechnologies.in"><b>MindScape Technologies</b></a>.
            </p>
          </div>
        </div>
        <div className=" mt-4 flex flex-col md:flex-row items-center">
          <div className="max-w-[656px] mb-4 md:mb-0 md:mr-4  ">
            <h1 className=" font-semibold text-xl mb-1">
              Kunal Khelkar - Founder & CEO Saksen Technologies
            </h1>
            <p>
              He is the dynamic Founder & CEO of Saksen Technologies, a
              prominent firm dedicated to providing top-tier technology and
              consulting services. With a background in engineering and business
              strategy, Kunal leads Saksen Technologies with a focus on
              innovation and customer satisfaction. His expertise in technology
              solutions and strategic management has been instrumental in
              driving the company's growth. Kunal's commitment to technology and
              collaboration has led to a partnership with MindScape Technologies
              on the Plantifo  project, aimed at enhancing the plant industry
              through seamless integration and networking solutions. Discover
              more about Saksen Technologies at <a className="text-green-600" href="https://saksentechnologies.com"><b>Saksen Technologies</b></a>.
            </p>
          </div>
          <img
            src="path-to-your-image.jpg"
            alt="Kunal Khelkar"
            className="w-full md:w-1/2 rounded-lg shadow-lg"
          />
        </div>
      </div>

      <div className="mb-8">
        <p className="text-3xl font-semibold mb-4">How It Works</p>
        <p className="text-justify mb-2">
          Plantifo  simplifies the plant buying and selling process for everyone
          involved{" "}
        </p>
        <ol className=" ">
          <li className="mb-2 ">
            1. Nurseries list their inventory and connect with other nurseries
            and customers.
          </li>
          <li className="mb-2 ">
            2. Customers scan QR codes to get detailed plant information and
            connect with local nurseries.
          </li>
          <li className="mb-2 ">
            3. Suppliers manage and fulfill orders efficiently through the app.
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <p className="text-3xl font-semibold mb-4">Community and Impact</p>
        <p className="text-justify mb-2">
          At Plantifo , we believe in the power of community and sustainability.
          We are committed to promoting eco-friendly practices and supporting
          local nurseries and suppliers. Our platform not only helps improve
          sales but also fosters a sense of community among plant lovers.
        </p>
      </div>

      <div className="mb-8">
        <p className="text-3xl font-semibold mb-4">Testimonials and Reviews</p>
        <ul className="flex   flex-wrap">
          <li className="py-8 sm:max-w-[45%] text-left border px-4 m-2">
            <div className="flex items-start">
              <img
                className="block h-10 w-10 max-w-full flex-shrink-0 rounded-full align-middle"
                src="../Assets/media/Springville_Clarkia.jpeg"
                alt=""
              />

              <div className="ml-6">
                <p className="mt-1 text-base text-gray-900">
                  "Plantifo  has transformed the way we manage our nursery. The
                  inventory management and analytics features are
                  game-changers!"
                </p>
                <p className="mt-2 text-sm font-bold text-gray-900">
                  John Lester
                </p>
                <p className=" text-sm text-gray-600">Nursery Owner</p>
              </div>
            </div>
          </li>
          <li className="py-8 sm:max-w-[45%] text-left border px-4 m-2">
            <div className="flex items-start">
              <img
                className="block h-10 w-10 max-w-full flex-shrink-0 rounded-full align-middle"
                src="../Assets/media/Springville_Clarkia.jpeg"
                alt=""
              />

              <div className="ml-6">
                <p className="mt-1 text-base text-gray-900">
                  "As a customer, I love the convenience of scanning QR codes to
                  get all the information I need about plants. It saves me so
                  much time!"
                </p>
                <p className="mt-2 text-sm font-bold text-gray-900">
                  Harsh Goyal
                </p>
                <p className=" text-sm text-gray-600">Customer</p>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className="my-8">
        <p className="text-3xl font-semibold mb-4">Partner with Us</p>
        <div className="flex flex-col gap-4 xl:flex-row items-center">
          <img
            src="path-to-your-image.jpg"
            alt="Introduction"
            className="w-full md:w-1/2 rounded-lg shadow-lg mr-2"
          />
          <div className="max-w-[656px] mb-4 md:mb-0 md:mr-4 text-justify ">
            <p>
              At Plantifo , we are more than just a groundbreaking app; we are
              part of a larger vision led by our parent companies, MindScape
              Technologies and Saksen Technologies. Our technology expertise and
              innovative solutions are at the heart of our business, and we are
              eager to coll borate with new clients and partners.
            </p>
            <h1 className=" font-semibold text-xl my-4">
              Why Partner with Us?
            </h1>
            <ul className=" list-disc">
              <li><span className=" font-bold underline">
                Innovative Solutions:</span> We provide cutting-edge technology and
                digital solutions tailored to meet the unique needs of
                businesses across various industries.
              </li>
              <li><span className=" font-bold underline">
                Customized Approach:</span> We work closely with our clients to
                understand their specific needs and challenges, offering
                personalized solutions that align with their business goals.
              </li>
              <li>

                <span className=" font-bold underline">Expertise and Experience:</span> With our extensive experience in
                software development, project management, and IT consulting, we
                deliver solutions that drive success and growth.
              </li>
              <li>
                <span className=" font-bold underline">
                  Proven Track Record:</span>  Our team has a strong track record of delivering high-quality projects on time and within budget, ensuring client satisfaction and long-term relationships.
              </li>
            </ul>
          </div>
        </div>
      </div>



      <div className="my-8">

        <section className=" ">
          <p className="text-3xl font-semibold ">Our Services</p>
          <div className="mx-auto grid max-w-lg gap-x-8 gap-y-12 px-4 py-12 md:max-w-screen-xl md:grid-cols-2 md:px-8 lg:grid-cols-2">


            <div className="border-black/40 relative border-4 px-4 pt-14 pb-8">
              <div className="absolute -top-8 left-8 bg-[rgb(245,243,243)]  px-4 text-green-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-16 w-16">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
                </svg>
              </div>
              <p className="mb-3 font-medium uppercase text-black">Custom Software Development</p>
              <p className="text-black-200"> From mobile apps to enterprise

                solutions, we create custom software designed to meet your business

                needs.</p>
            </div>

            <div className="border-black/40 relative border-4 px-4 pt-14 pb-8">
              <div className="absolute -top-8 left-8 bg-[rgb(245,243,243)] px-4 text-green-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-16 w-16">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
                </svg>
              </div>
              <p className="mb-3 font-medium uppercase text-black">Digital Transformation</p>
              <p className="text-black-200">Enhance your business operations with our
                digital transformation services, including system integration,
                automation, and modernization.</p>
            </div>

            <div className="border-black/40 relative border-4 px-4 pt-14 pb-8">
              <div className="absolute -top-8 left-8 bg-[rgb(245,243,243)] px-4 text-green-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-16 w-16">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
                </svg>
              </div>
              <p className="mb-3 font-medium uppercase text-black">Consulting and Strategy</p>
              <p className="text-black-200">Leverage our expertise in IT consulting and
                strategy to drive innovation and achieve your business objectives.</p>
            </div>



            <div className="border-black/40 relative border-4 px-4 pt-14 pb-8">
              <div className="absolute -top-8 left-8 bg-[rgb(245,243,243)]  px-4 text-green-600 ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-16 w-16">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
                </svg>
              </div>
              <p className="mb-3 font-medium uppercase text-black">Maintenance and Support</p>
              <p className="text-black-200">Enjoy peace of mind with our ongoing
                maintenance and support services, ensuring your technology remains

                up-to-date and functional.</p>
            </div>
          </div>
        </section>






      </div>




      <div className="my-8">

        <ContactUs />
      </div>


      {cartShow && <CartContainer />} 
    </div>
  );
}

export default AboutUsContainer;
