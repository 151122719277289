import I1 from "../img/i1.png";
import F1 from "../img/f1.png";
import C3 from "../img/c3.png";
import Fi1 from "../img/fi1.png";

export const heroData = [
  {
    id: 1,
    name: "Aglaonema Plant",
    decp: "Indoor/Outdoor",
    prize: "599",
    imageSrc: I1,
  },
  {
    id: 2,
    name: "Ixora orange Plant",
    decp: "Flowering",
    prize: "1299",
    imageSrc: F1,
  },
  {
    id: 3,
    name: "Monstera Deliciosa Plant",
    decp: "Pet-Friendly",
    prize: "899",
    imageSrc: C3,
  },
  {
    id: 4,
    name: "Syngonium Mini Plant",
    decp: "Office-Plant",
    prize: "759",
    imageSrc: Fi1,
  },
];

export const categories = [
  {
    id: 1,
    name: "Indoor",
    type: "indoor",
  },
  {
    id: 2,
    name: "Outdoor",
    type: "outdoor",
  },
  {
    id: 3,
    name: "Flowering",
    type: "flowering",
  },
  {
    id: 4,
    name: "Hanging Plant",
    type: "hanging_plant",
  },
  {
    id: 5,
    name: "Pet-friendly",
    type: "pet-friendly",
  },
  {
    id: 6,
    name: "XL Floor Standing Plant",
    type: "XL_floor_standing_plant",
  },
  {
    id: 7,
    name: "Office Plant",
    type: "office_plant",
  },
  {
    id: 8,
    name: "Mossballs",
    type: "mossballs",
  }
];

export const footerData = [
  {
    id: 1,
    img: "https://cdn.shopify.com/s/files/1/0284/2450/files/plant_60x.png?v=1586856424",
    header: "500+ Plants Available",
    desc: "Found great new Plants!",
  },
  {
    id: 2,
    img: "https://cdn.shopify.com/s/files/1/0284/2450/files/cash_on_delivery_60x.png?v=1586857051",
    header: "Free to Use",
    desc: "Available across India!",
  },
  {
    id: 3,
    img: "https://cdn.shopify.com/s/files/1/0284/2450/files/support_60x.png?v=1586944291",
    header: "Quickly Available",
    desc: "Available with Care and Love!",
  },
  {
    id: 4,
    img: "https://cdn.shopify.com/s/files/1/0284/2450/files/delivery_60x.png?v=1586943729",
    header: "Help & Support",
    desc: "Friendly & Quick!",
  },
];
