import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { StateProvider } from "./context/StateProvider";
import { initialState } from "./context/initialState";
import reducer from "./context/reducer";
import { store } from "./context/store";
import { Provider } from 'react-redux'

ReactDOM.render(
  <BrowserRouter>
    <StateProvider initialState={initialState} reducer={reducer}>
    <Provider store={store}>
      <App />
    </Provider>
    </StateProvider>
  </BrowserRouter>,
  document.querySelector("#root")
);
