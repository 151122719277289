const plants = [
    {
        id: "01",
        image: "",
        plantName: "African Violet",
        commonName: "African Violet",
        plantScientificName: "Saintpaulia",
        plantSynonyms: ["Saintpaulia"],
        rating: 4.5,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Needs indirect light",
        bestFor: ["Indoor decoration", "office desks"],
        highlight: "Beautiful small flowers, easy to care for",
        description: "African violets are small flowering plants known for their vibrant blooms and fuzzy leaves. They thrive indoors and are safe for pets.",
        longDescription: "African violets are popular houseplants that bloom in various colors, including purple, pink, and white. They have a rosette of fuzzy leaves and prefer indirect light and high humidity. Regular watering and feeding promote year-round blooming.",
        careTips: {
            light: "Indirect light",
            water: "Keep soil moist but not soggy",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "High",
            humidityNumber: "60%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, African violet mix"
        },
        tips: [
            "Use lukewarm water for watering",
            "Avoid getting water on the leaves",
            "Regularly remove dead flowers and leaves"
        ]
    },
    {
        id: "02",
        plantName: "Christmas Cactus",
        commonName: "Christmas Cactus",
        plantScientificName: "Schlumbergera bridgesii",
        plantSynonyms: ["Zygocactus"],
        rating: 4.3,
        type: ["indoor", "flowering", "pet-friendly", "hanging_plant", "office_plant"],
        note: "Blooms during winter",
        bestFor: ["Indoor hanging baskets"],
        highlight: "Winter blooming, easy to propagate",
        description: "The Christmas cactus is a popular holiday plant known for its bright, tubular flowers. It is safe for pets and thrives indoors.",
        longDescription: "Christmas cacti are epiphytic cacti that bloom in shades of red, pink, and white. They are native to Brazilian rainforests and prefer indirect light and moderate watering. Their segmented stems make them easy to propagate.",
        careTips: {
            light: "Indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "60-70°F (15-21°C)",
            humidity: "Moderate",
            humidityNumber: "50%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, cactus mix"
        },
        tips: [
            "Reduce watering after blooming",
            "Rotate the plant for even growth",
            "Fertilise monthly during the growing season"
        ]
    },
    {
        id: "03",
        plantName: "Spider Plant",
        commonName: "Spider Plant",
        plantScientificName: "Chlorophytum comosum",
        plantSynonyms: ["Anthericum comosum"],
        rating: 4.6,
        type: ["indoor", "flowering", "pet-friendly", "hanging_plant", "office_plant"],
        note: "Produces baby plants on long stems",
        bestFor: ["Hanging baskets", "shelves"],
        highlight: "Air-purifying, easy to care for",
        description: "Spider plants are known for their long, arching leaves and small white flowers. They are safe for pets and improve indoor air quality.",
        longDescription: "Spider plants are resilient and adaptable, making them ideal for beginners. They produce spiderettes or baby plants that can be easily propagated. Spider plants thrive in various light conditions and require minimal care.",
        careTips: {
            light: "Indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "55-80°F (13-27°C)",
            humidity: "Moderate",
            humidityNumber: "40-60%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, general potting mix"
        },
        tips: [
            "Trim brown tips to maintain appearance",
            "Mist the leaves to increase humidity",
            "Repot annually to refresh the soil"
        ]
    },
    {
        id: "04",
        plantName: "Calathea",
        commonName: "Peacock Plant",
        plantScientificName: "Calathea makoyana",
        plantSynonyms: ["Goeppertia makoyana"],
        rating: 4.2,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Attractive patterned leaves",
        bestFor: ["Shady spots", "tabletops"],
        highlight: "Ornamental foliage, safe for pets",
        description: "Calathea plants are known for their striking, patterned leaves and occasional small flowers. They are safe for pets and add a tropical touch to interiors.",
        longDescription: "Calatheas are tropical plants that thrive in low to medium light conditions. Their leaves fold up at night and open in the morning, showcasing intricate patterns. They prefer high humidity and consistent moisture.",
        careTips: {
            light: "Low to medium light",
            water: "Keep soil consistently moist",
            temperature: "60-75°F (16-24°C)",
            humidity: "High",
            humidityNumber: "60-70%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, peat-based mix"
        },
        tips: [
            "Avoid direct sunlight to prevent leaf burn",
            "Use distilled water to prevent leaf spots",
            "Increase humidity with a humidifier or pebble tray"
        ]
    },
    {
        id: "05",
        plantName: "Parlor Palm",
        commonName: "Parlor Palm",
        plantScientificName: "Chamaedorea elegans",
        plantSynonyms: ["Neanthe bella"],
        rating: 4.4,
        type: ["indoor", "flowering", "pet-friendly", "XL_floor_standing_plant", "office_plant"],
        note: "Slow-growing, compact palm",
        bestFor: ["Low-light rooms", "offices"],
        highlight: "Low maintenance, air-purifying",
        description: "The parlor palm is a popular indoor palm known for its feathery fronds and air-purifying qualities. It is safe for pets and grows well in low light.",
        longDescription: "Parlor palms are native to rainforests in Mexico and Guatemala. They are slow-growing and can reach up to 6 feet indoors. They prefer indirect light and moderate watering. Their ability to thrive in low light makes them ideal for offices and homes.",
        careTips: {
            light: "Low to medium light",
            water: "Water when the top inch of soil is dry",
            temperature: "65-80°F (18-27°C)",
            humidity: "Moderate",
            humidityNumber: "40-50%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, peat-based mix"
        },
        tips: [
            "Wipe leaves to remove dust",
            "Avoid overwatering to prevent root rot",
            "Fertilize monthly during the growing season"
        ]
    },
    {
        id: "06",
        plantName: "Boston Fern",
        commonName: "Boston Fern",
        plantScientificName: "Nephrolepis exaltata",
        plantSynonyms: ["Sword fern"],
        rating: 4.1,
        type: ["indoor", "flowering", "pet-friendly", "hanging_plant"],
        note: "Thrives in high humidity",
        bestFor: ["Hanging baskets", "bathrooms"],
        highlight: "Elegant fronds, air-purifying",
        description: "Boston ferns are known for their lush, arching fronds and air-purifying qualities. They are safe for pets and prefer high humidity.",
        longDescription: "Boston ferns are popular houseplants that add a touch of elegance to any room. They thrive in bright, indirect light and high humidity. Regular misting and keeping the soil consistently moist are essential for their health.",
        careTips: {
            light: "Indirect light",
            water: "Keep soil consistently moist",
            temperature: "60-75°F (16-24°C)",
            humidity: "High",
            humidityNumber: "60-70%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, peat-based mix"
        },
        tips: [
            "Mist regularly to increase humidity",
            "Place on a pebble tray to maintain moisture",
            "Trim brown fronds to keep the plant tidy"
        ]
    },
    {
        id: "07",
        plantName: "Orchid",
        commonName: "Moth Orchid",
        plantScientificName: "Phalaenopsis",
        plantSynonyms: ["Butterfly orchid"],
        rating: 4.7,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Long-lasting blooms",
        bestFor: ["Bright rooms", "offices"],
        highlight: "Elegant flowers, easy to care for",
        description: "Moth orchids are popular for their stunning, long-lasting flowers and ease of care. They are safe for pets and thrive in bright, indirect light.",
        longDescription: "Phalaenopsis orchids are native to Southeast Asia and are known for their striking flowers that can last for months. They prefer bright, indirect light and moderate watering. With the right care, they can bloom multiple times a year.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: "50-70%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, orchid mix"
        },
        tips: [
            "Avoid getting water on the leaves",
            "Use orchid-specific fertilizer",
            "Repot every 1-2 years"
        ]
    },
    {
        id: "08",
        plantName: "Friendship Plant",
        commonName: "Friendship Plant",
        plantScientificName: "Pilea involucrata",
        plantSynonyms: ["Moon Valley"],
        rating: 4.3,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Easy to propagate",
        bestFor: ["Small spaces", "desks"],
        highlight: "Textured leaves, low maintenance",
        description: "Friendship plants are known for their textured, wrinkled leaves and their ease of propagation. They are safe for pets and make excellent small-space plants.",
        longDescription: "The Friendship Plant, or Pilea involucrata, has attractive, dark green leaves with a unique texture. It is a low-maintenance plant that thrives in a variety of indoor environments. It's an excellent choice for small spaces and can be propagated easily from cuttings.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: "50%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, general potting mix"
        },
        tips: [
            "Regularly remove dead leaves",
            "Propagate by cutting and rooting in water",
            "Avoid direct sunlight to prevent leaf burn"
        ]
    },
    {
        id: "09",
        plantName: "ZZ Plant",
        commonName: "ZZ Plant",
        plantScientificName: "Zamioculcas zamiifolia",
        plantSynonyms: ["Zanzibar Gem"],
        rating: 4.5,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Extremely drought-tolerant",
        bestFor: ["Low light areas", "offices"],
        highlight: "Drought tolerance, low maintenance",
        description: "The ZZ plant is known for its glossy, dark green leaves and ability to thrive in low light and drought conditions. It is safe for pets and very easy to care for.",
        longDescription: "ZZ plants are native to East Africa and are renowned for their ability to survive in challenging conditions. They have waxy, dark green leaves that can tolerate low light and infrequent watering. They are ideal for busy or low-light environments.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Water when the soil is completely dry",
            temperature: "60-75°F (15-24°C)",
            humidity: "Low to moderate",
            humidityNumber: "30-50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining, cactus mix"
        },
        tips: [
            "Allow soil to dry out between waterings",
            "Wipe leaves occasionally to remove dust",
            "Avoid overwatering to prevent root rot"
        ]
    },
    {
        id: "10",
        plantName: "Pothos",
        commonName: "Devil’s Ivy",
        plantScientificName: "Epipremnum aureum",
        plantSynonyms: ["Scindapsus aureus"],
        rating: 4.8,
        type: ["indoor", "flowering", "pet-friendly", "hanging_plant", "office_plant"],
        note: "Very easy to grow",
        bestFor: ["Low light", "beginner gardeners"],
        highlight: "Fast-growing, air-purifying",
        description: "Pothos is a popular indoor plant known for its trailing vines and heart-shaped leaves. It is very easy to grow and maintain, making it perfect for beginners.",
        longDescription: "Pothos, also known as Devil’s Ivy, is a hardy plant that thrives in a range of light conditions. It has trailing vines with heart-shaped leaves that come in various shades of green and gold. Pothos is excellent for purifying indoor air and can grow quickly with minimal care.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "60-80°F (15-27°C)",
            humidity: "Moderate",
            humidityNumber: "40-60%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining, general potting mix"
        },
        tips: [
            "Trim regularly to maintain shape",
            "Can be propagated in water",
            "Remove dead or yellowing leaves to encourage new growth"
        ]
    },
    {
        id: "11",
        plantName: "Bamboo Palm",
        commonName: "Bamboo Palm",
        plantScientificName: "Chamaedorea seifrizii",
        plantSynonyms: ["Reed Palm"],
        rating: 4.2,
        type: ["indoor", "flowering", "pet-friendly", "XL_floor_standing_plant", "office_plant"],
        note: "Effective air purifier",
        bestFor: ["Offices", "large rooms"],
        highlight: "Air-purifying, tropical look",
        description: "The Bamboo Palm is a popular indoor plant known for its slender, bamboo-like stems and lush foliage. It is effective at purifying indoor air and adds a tropical feel to any space.",
        longDescription: "Bamboo Palms are native to the rainforests of Central America. They have a graceful appearance with slender stems and feathery fronds. They thrive in bright, indirect light and help to remove indoor pollutants.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "High",
            humidityNumber: "60-70%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, peat-based mix"
        },
        tips: [
            "Increase humidity with a pebble tray or humidifier",
            "Fertilize every 6-8 weeks during the growing season",
            "Regularly dust leaves to maintain appearance"
        ]
    },
    {
        id: "12",
        plantName: "Rubber Plant",
        commonName: "Rubber Tree",
        plantScientificName: "Ficus elastica",
        plantSynonyms: ["Rubber Fig"],
        rating: 4.4,
        type: ["indoor", "flowering", "pet-friendly", "XL_floor_standing_plant", "office_plant"],
        note: "Large, glossy leaves",
        bestFor: ["Large spaces", "offices"],
        highlight: "Low maintenance, large foliage",
        description: "The Rubber Plant is known for its large, glossy leaves and is an excellent choice for adding a touch of greenery to large spaces. It is easy to care for and thrives in a variety of indoor conditions.",
        longDescription: "Rubber Plants are native to Southeast Asia and are known for their bold, glossy leaves. They can grow up to 10 feet tall indoors and adapt well to different light conditions. They are relatively low maintenance and can tolerate periods of neglect.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "60-75°F (15-24°C)",
            humidity: "Low to moderate",
            humidityNumber: "40-50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining, general potting mix"
        },
        tips: [
            "Wipe leaves occasionally to remove dust",
            "Prune regularly to maintain shape",
            "Repot every 2-3 years for better growth"
        ]
    },
    {
        id: "13",
        plantName: "Aloe Vera",
        commonName: "Aloe Vera",
        plantScientificName: "Aloe barbadensis miller",
        plantSynonyms: ["Medicinal Aloe"],
        rating: 4.6,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Useful for its gel",
        bestFor: ["Sunny windowsills", "offices"],
        highlight: "Medicinal properties, easy to grow",
        description: "Aloe Vera is known for its soothing gel and spiky, succulent leaves. It is a low-maintenance plant that thrives in sunny locations and is safe for pets.",
        longDescription: "Aloe Vera is a succulent plant with fleshy, pointed leaves that contain a gel used for its soothing properties. It is easy to grow and thrives in bright, indirect light. Aloe Vera is often used in skincare and can be grown indoors with minimal care.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water when the soil is completely dry",
            temperature: "59-77°F (15-25°C)",
            humidity: "Low",
            humidityNumber: "30-50%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, cactus mix"
        },
        tips: [
            "Avoid overwatering to prevent root rot",
            "Use well-draining soil and pots with drainage holes",
            "Remove any damaged or dried leaves"
        ]
    },
    {
        id: "14",
        plantName: "Chinese Evergreen",
        commonName: "Chinese Evergreen",
        plantScientificName: "Aglaonema",
        plantSynonyms: ["Aglaonema"],
        rating: 4.4,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Tolerates low light",
        bestFor: ["Offices", "low light rooms"],
        highlight: "Easy to care for, decorative leaves",
        description: "Chinese Evergreens are popular for their attractive foliage and tolerance of low light conditions. They are easy to care for and suitable for various indoor environments.",
        longDescription: "Chinese Evergreens are known for their varied and attractive foliage, which can come in shades of green, silver, and red. They are hardy plants that thrive in low light and can tolerate a range of indoor conditions.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "60-80°F (15-27°C)",
            humidity: "Moderate",
            humidityNumber: "40-60%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining, general potting mix"
        },
        tips: [
            "Avoid direct sunlight to prevent leaf burn",
            "Fertilize every 4-6 weeks during the growing season",
            "Remove yellowing leaves to promote new growth"
        ]
    },
    {
        id: "15",
        plantName: "Snake Plant",
        commonName: "Snake Plant",
        plantScientificName: "Sansevieria trifasciata",
        plantSynonyms: ["Mother-in-law’s Tongue"],
        rating: 4.7,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Excellent air purifier",
        bestFor: ["Low light areas", "offices"],
        highlight: "Air-purifying, very low maintenance",
        description: "The Snake Plant is known for its upright, sword-like leaves and air-purifying abilities. It is extremely low-maintenance and thrives in a variety of conditions.",
        longDescription: "Snake Plants, or Sansevieria, are hardy plants with upright, sword-shaped leaves that come in various shades of green and gray. They are excellent for air purification and can tolerate low light and infrequent watering.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Water when the soil is completely dry",
            temperature: "60-80°F (15-27°C)",
            humidity: "Low",
            humidityNumber: "30-50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining, cactus mix"
        },
        tips: [
            "Allow soil to dry out completely between waterings",
            "Use a well-draining potting mix",
            "Remove any dead or damaged leaves"
        ]
    },
    {
        id: "16",
        plantName: "Cast Iron Plant",
        commonName: "Cast Iron Plant",
        plantScientificName: "Aspidistra elatior",
        plantSynonyms: [],
        rating: 4.3,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Extremely tough plant",
        bestFor: ["Low light areas"],
        highlight: "Hardiness, minimal care",
        description: "The Cast Iron Plant is known for its durability and ability to thrive in low light conditions. It has large, glossy leaves and requires minimal maintenance.",
        longDescription: "Aspidistra elatior, or Cast Iron Plant, is valued for its robust nature and ability to survive in a variety of indoor conditions. It has large, dark green leaves and can tolerate neglect, making it ideal for low-light areas.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: "40-50%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, general potting mix"
        },
        tips: [
            "Avoid direct sunlight to prevent leaf burn",
            "Wipe leaves occasionally to remove dust",
            "Minimal fertilization needed"
        ]
    },
    {
        id: "17",
        plantName: "Peace Lily",
        commonName: "Peace Lily",
        plantScientificName: "Spathiphyllum",
        plantSynonyms: [],
        rating: 4.6,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Blooms with white flowers",
        bestFor: ["Shaded areas", "offices"],
        highlight: "Air-purifying, beautiful blooms",
        description: "Peace Lilies are known for their elegant white flowers and air-purifying qualities. They are ideal for low light areas and require regular watering.",
        longDescription: "Spathiphyllum, or Peace Lily, is popular for its attractive white flowers and glossy green leaves. It thrives in low light and is effective at removing indoor air pollutants. It requires consistent moisture and is an excellent choice for indoor spaces.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Keep soil consistently moist",
            temperature: "65-80°F (18-27°C)",
            humidity: "Moderate to high",
            humidityNumber: "50-60%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining, peat-based mix"
        },
        tips: [
            "Mist leaves to increase humidity",
            "Remove spent flowers regularly",
            "Repot when roots become crowded"
        ]
    },
    {
        id: "18",
        plantName: "Boston Fern",
        commonName: "Boston Fern",
        plantScientificName: "Nephrolepis exaltata",
        plantSynonyms: [],
        rating: 4.4,
        type: ["indoor", "flowering", "pet-friendly", "hanging_plant"],
        note: "Requires high humidity",
        bestFor: ["Bathrooms", "humid rooms"],
        highlight: "Lush foliage, humidity lover",
        description: "Boston Ferns are known for their lush, feathery fronds and preference for high humidity. They make great hanging plants and thrive in moist environments.",
        longDescription: "Nephrolepis exaltata, or Boston Fern, has delicate, feather-like fronds that add a lush touch to any room. It thrives in high humidity and indirect light, making it perfect for bathrooms and other humid environments.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist",
            temperature: "60-75°F (15-24°C)",
            humidity: "High",
            humidityNumber: "60-70%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, peat-based mix"
        },
        tips: [
            "Increase humidity with a pebble tray or humidifier",
            "Keep away from direct sunlight",
            "Regularly mist leaves to maintain humidity"
        ]
    },
    {
        id: "19",
        plantName: "Spider Plant",
        commonName: "Spider Plant",
        plantScientificName: "Chlorophytum comosum",
        plantSynonyms: [],
        rating: 4.5,
        type: ["indoor", "flowering", "pet-friendly", "hanging_plant", "office_plant"],
        note: "Produces 'babies' or offsets",
        bestFor: ["Beginners", "hanging baskets"],
        highlight: "Easy to propagate, air-purifying",
        description: "Spider Plants are known for their arching leaves and ability to produce 'babies' or offsets. They are easy to care for and are effective at purifying indoor air.",
        longDescription: "Chlorophytum comosum, or Spider Plant, is an adaptable and low-maintenance plant that produces numerous offsets. It thrives in a variety of light conditions and is great for air purification.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: "40-50%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, general potting mix"
        },
        tips: [
            "Regularly trim dead or brown leaves",
            "Propagate by planting offsets",
            "Use a hanging basket to showcase its cascading foliage"
        ]
    },
    {
        id: "20",
        plantName: "Pilea Peperomioides",
        commonName: "Chinese Money Plant",
        plantScientificName: "Pilea peperomioides",
        plantSynonyms: ["Pancake Plant"],
        rating: 4.4,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Unique round leaves",
        bestFor: ["Small spaces", "trendy decor"],
        highlight: "Distinctive leaves, easy care",
        description: "The Chinese Money Plant is known for its unique, round leaves and is a popular choice for trendy indoor spaces. It is easy to care for and can be a conversation starter.",
        longDescription: "Pilea peperomioides, or Chinese Money Plant, is valued for its distinctive, round leaves and easy-care nature. It thrives in bright, indirect light and is a favorite among indoor gardeners for its unusual appearance.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: "40-50%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, general potting mix"
        },
        tips: [
            "Rotate the plant regularly to ensure even growth",
            "Propagate by separating offsets",
            "Keep away from direct sunlight to prevent leaf burn"
        ]
    },
    {
        id: "21",
        plantName: "Dracaena",
        commonName: "Dracaena",
        plantScientificName: "Dracaena",
        plantSynonyms: [],
        rating: 4.3,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Varied leaf patterns",
        bestFor: ["Offices", "living rooms"],
        highlight: "Varied foliage, low maintenance",
        description: "Dracaena plants are known for their varied leaf patterns and easy maintenance. They are suitable for various indoor environments and add a touch of elegance.",
        longDescription: "Dracaena is a genus of plants with many species, each offering unique leaf patterns and colors. They are adaptable to different light conditions and require minimal care, making them a popular choice for indoor spaces.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Low to moderate",
            humidityNumber: "30-50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining, general potting mix"
        },
        tips: [
            "Remove yellowing leaves to promote new growth",
            "Rotate plant regularly for even growth",
            "Avoid overwatering to prevent root rot"
        ]
    },
    {
        id: "22",
        plantName: "ZZ Plant",
        commonName: "ZZ Plant",
        plantScientificName: "Zamioculcas zamiifolia",
        plantSynonyms: ["Zanzibar Gem"],
        rating: 4.6,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Very tolerant of neglect",
        bestFor: ["Low light", "busy areas"],
        highlight: "Resilient, low care",
        description: "ZZ Plants are highly resilient and can thrive in a range of indoor conditions. They are known for their shiny leaves and ability to tolerate neglect.",
        longDescription: "Zamioculcas zamiifolia, or ZZ Plant, is a tough plant that can handle a variety of indoor conditions. It has shiny, dark green leaves and can go long periods without water. It's an ideal choice for low-light areas and busy spaces.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Water when the soil is completely dry",
            temperature: "60-75°F (15-24°C)",
            humidity: "Low to moderate",
            humidityNumber: "30-50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining, cactus mix"
        },
        tips: [
            "Avoid overwatering",
            "Use well-draining soil and pots with drainage holes",
            "Wipe leaves occasionally to remove dust"
        ]
    },
    {
        id: "23",
        plantName: "Philodendron",
        commonName: "Philodendron",
        plantScientificName: "Philodendron",
        plantSynonyms: [],
        rating: 4.5,
        type: ["indoor", "flowering", "pet-friendly", "hanging_plant"],
        note: "Many varieties available",
        bestFor: ["Hanging baskets", "low light"],
        highlight: "Varied leaf shapes, easy care",
        description: "Philodendrons come in many varieties with different leaf shapes and are known for their adaptability and ease of care. They are great for indoor environments.",
        longDescription: "Philodendrons are a diverse genus of plants with various species offering different leaf shapes and colors. They are well-suited for indoor environments and are easy to care for, making them a popular choice for home decor.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: "40-50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining, general potting mix"
        },
        tips: [
            "Regularly prune to encourage bushier growth",
            "Rotate plant for even growth",
            "Keep away from direct sunlight"
        ]
    },
    {
        id: "24",
        plantName: "Aloe Vera",
        commonName: "Aloe Vera",
        plantScientificName: "Aloe barbadensis",
        plantSynonyms: [],
        rating: 4.7,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Medicinal properties",
        bestFor: ["Dry climates", "windowsills"],
        highlight: "Medicinal uses, low maintenance",
        description: "Aloe Vera is known for its medicinal properties and ease of care. It thrives in dry conditions and can be a useful addition to any indoor space.",
        longDescription: "Aloe barbadensis, or Aloe Vera, is a succulent known for its gel-filled leaves with medicinal properties. It is easy to grow and thrives in bright light and dry conditions, making it a practical and attractive indoor plant.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water when the soil is completely dry",
            temperature: "60-75°F (15-24°C)",
            humidity: "Low",
            humidityNumber: "30-40%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, cactus mix"
        },
        tips: [
            "Allow soil to dry out completely between waterings",
            "Avoid getting water on the leaves",
            "Use well-draining pots"
        ]
    },
    {
        id: "25",
        plantName: "Money Tree",
        commonName: "Money Tree",
        plantScientificName: "Pachira aquatica",
        plantSynonyms: [],
        rating: 4.4,
        type: ["indoor", "flowering", "pet-friendly", "office_plant"],
        note: "Associated with good luck",
        bestFor: ["Indoor environments"],
        highlight: "Unique braided trunk, luck charm",
        description: "The Money Tree is associated with good luck and prosperity. It features a unique braided trunk and lush green leaves, making it a popular choice for indoor spaces.",
        longDescription: "Pachira aquatica, or Money Tree, is often associated with good fortune and is characterized by its braided trunk and glossy green leaves. It is a hardy plant that thrives in a range of indoor conditions and is believed to bring good luck.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water when the top inch of soil is dry",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: "40-50%",
            toxicity: "Non-toxic to pets",
            soil: "Well-draining, general potting mix"
        },
        tips: [
            "Prune regularly to maintain shape",
            "Rotate plant for even growth",
            "Avoid overwatering to prevent root rot"
        ]
    },
    {
        id: "26",
        plantName: "Fiddle Leaf Fig",
        commonName: "Fiddle Leaf Fig",
        plantScientificName: "Ficus lyrata",
        plantSynonyms: ["Fiddle Leaf Fig"],
        rating: 4.5,
        type: ["XL_floor_standing_plant"],
        note: "Popular for its large, glossy leaves",
        bestFor: ["Large spaces"],
        highlight: "Dramatic foliage",
        description: "A striking plant with large, violin-shaped leaves.",
        longDescription: "The Fiddle Leaf Fig is known for its broad, leathery leaves that create a bold, architectural statement. It requires bright, indirect light to thrive and can grow quite tall, making it ideal for larger rooms.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: "50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Rotate the plant periodically for even growth."
        ]
    },
    {
        id: "27",
        plantName: "Rubber Plant",
        commonName: "Rubber Plant",
        plantScientificName: "Ficus elastica",
        plantSynonyms: ["Rubber Plant"],
        rating: 4.0,
        type: ["XL_floor_standing_plant"],
        note: "Known for its thick, shiny leaves",
        bestFor: ["Low light environments"],
        highlight: "Large, glossy leaves",
        description: "A hardy plant with large, leathery leaves that can grow up to 8 feet tall.",
        longDescription: "The Rubber Plant is an easy-to-care-for option that can adapt to a variety of light conditions. It has broad, dark green leaves that can add a touch of elegance to any room.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: "50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Dust leaves occasionally to keep them clean."
        ]
    },
    {
        id: "28",
        plantName: "Bird of Paradise",
        commonName: "Bird of Paradise",
        plantScientificName: "Strelitzia reginae",
        plantSynonyms: ["Bird of Paradise"],
        rating: 4.5,
        type: ["XL_floor_standing_plant"],
        note: "Large, banana-like leaves",
        bestFor: ["Tropical-themed rooms"],
        highlight: "Exotic appearance",
        description: "A dramatic plant with large, banana-like leaves that resemble the shape of a bird’s wings.",
        longDescription: "Bird of Paradise is an exotic plant with striking foliage that can add a touch of the tropics to any indoor space. It grows best in bright, indirect light and can reach impressive heights.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist but not soggy",
            temperature: "65-70°F (18-21°C)",
            humidity: "High",
            humidityNumber: "60%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Prune old leaves to encourage new growth."
        ]
    },
    {
        id: "29",
        plantName: "Kentia Palm",
        commonName: "Kentia Palm",
        plantScientificName: "Howea forsteriana",
        plantSynonyms: ["Kentia Palm"],
        rating: 4.2,
        type: ["XL_floor_standing_plant"],
        note: "Elegant, arching fronds",
        bestFor: ["Indoor palms"],
        highlight: "Graceful appearance",
        description: "A tall palm with elegant, arching fronds that adds a touch of sophistication.",
        longDescription: "The Kentia Palm is known for its graceful, arching fronds and tolerance of low light conditions. It is a great choice for adding a tropical feel to any indoor space.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: "50%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Avoid placing in direct sunlight to prevent leaf burn."
        ]
    },
    {
        id: "30",
        plantName: "Monstera",
        commonName: "Monstera",
        plantScientificName: "Monstera deliciosa",
        plantSynonyms: ["Monstera"],
        rating: 4.7,
        type: ["XL_floor_standing_plant"],
        note: "Unique, fenestrated leaves",
        bestFor: ["Statement plant"],
        highlight: "Eye-catching foliage",
        description: "Known for its large, unique leaves with natural splits and holes.",
        longDescription: "The Monstera, or Swiss Cheese Plant, has large, dramatic leaves with characteristic splits. It thrives in bright, indirect light and can grow quite large, making it a focal point in any room.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-85°F (18-29°C)",
            humidity: "High",
            humidityNumber: "60%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Support large leaves with a stake to keep them upright."
        ]
    },
    {
        id: "31",
        plantName: "Dracaena",
        commonName: "Dracaena",
        plantScientificName: "Dracaena fragrans",
        plantSynonyms: ["Dracaena"],
        rating: 4.0,
        type: ["XL_floor_standing_plant"],
        note: "Tall and upright",
        bestFor: ["Indoor corners"],
        highlight: "Striking form",
        description: "An upright plant with long, slender leaves that add vertical interest.",
        longDescription: "The Dracaena is a versatile plant with long, arching leaves that can add height and elegance to any room. It is low-maintenance and can tolerate a range of indoor conditions.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: "50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Wipe leaves with a damp cloth to remove dust."
        ]
    },
    {
        id: "32",
        plantName: "Yucca",
        commonName: "Yucca",
        plantScientificName: "Yucca elephantipes",
        plantSynonyms: ["Yucca"],
        rating: 4.3,
        type: ["XL_floor_standing_plant"],
        note: "Stiff, sword-like leaves",
        bestFor: ["Modern decor"],
        highlight: "Bold, sculptural form",
        description: "A bold plant with stiff, sword-like leaves and a trunk-like stem.",
        longDescription: "Yucca is known for its striking, sword-shaped leaves and tall, upright form. It’s ideal for adding a modern touch to any indoor space and is relatively easy to care for.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-80°F (15-27°C)",
            humidity: "Low to moderate",
            humidityNumber: "40%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Avoid overwatering to prevent root rot."
        ]
    },
    {
        id: "33",
        plantName: "Parlor Palm",
        commonName: "Parlor Palm",
        plantScientificName: "Chamaedorea elegans",
        plantSynonyms: ["Parlor Palm"],
        rating: 4.1,
        type: ["XL_floor_standing_plant"],
        note: "Compact and elegant",
        bestFor: ["Smaller spaces"],
        highlight: "Fine-textured fronds",
        description: "A compact palm with elegant, feathery fronds that thrives indoors.",
        longDescription: "The Parlor Palm is a small, elegant palm with fine-textured fronds. It’s perfect for adding a touch of greenery to smaller spaces and can adapt to lower light conditions.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Keep soil consistently moist but not soggy",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: "50%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Prune dead or yellowing fronds to maintain appearance."
        ]
    },
    {
        id: "34",
        plantName: "Areca Palm",
        commonName: "Areca Palm",
        plantScientificName: "Dypsis lutescens",
        plantSynonyms: ["Areca Palm"],
        rating: 4.6,
        type: ["XL_floor_standing_plant"],
        note: "Feathery, arching fronds",
        bestFor: ["Bright, tropical decor"],
        highlight: "Lush, green appearance",
        description: "A graceful palm with feathery, arching fronds that create a tropical look.",
        longDescription: "The Areca Palm, also known as the Butterfly Palm, has a lush, tropical appearance with delicate, feathery fronds. It thrives in bright, indirect light and can grow quite tall, making it a stunning focal point.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist but not soggy",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "High",
            humidityNumber: "60%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Regular misting can help increase humidity."
        ]
    },
    {
        id: "35",
        plantName: "Cast Iron Plant",
        commonName: "Cast Iron Plant",
        plantScientificName: "Aspidistra elatior",
        plantSynonyms: ["Cast Iron Plant"],
        rating: 4.2,
        type: ["XL_floor_standing_plant"],
        note: "Extremely durable",
        bestFor: ["Low-maintenance decor"],
        highlight: "Robust and hardy",
        description: "A very hardy plant with dark green, glossy leaves that is virtually indestructible.",
        longDescription: "The Cast Iron Plant is known for its durability and ability to thrive in a variety of indoor conditions. Its dark green leaves are glossy and can withstand neglect, making it a great choice for busy spaces.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Low to moderate",
            humidityNumber: "40%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Avoid placing in direct sunlight to prevent leaf burn."
        ]
    },
    {
        id: "36",
        plantName: "Ponytail Palm",
        commonName: "Ponytail Palm",
        plantScientificName: "Beaucarnea recurvata",
        plantSynonyms: ["Ponytail Palm"],
        rating: 4.3,
        type: ["XL_floor_standing_plant"],
        note: "Unique, bulbous trunk",
        bestFor: ["Unique indoor decor"],
        highlight: "Distinctive shape",
        description: "A unique plant with a bulbous trunk and long, curly leaves that resemble a ponytail.",
        longDescription: "The Ponytail Palm features a swollen trunk that stores water and long, slender leaves that cascade from the top. It’s a distinctive plant that can add character to any indoor space.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Low to moderate",
            humidityNumber: "40%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Minimal watering needed; allow soil to dry out between waterings."
        ]
    },
    {
        id: "37",
        plantName: "ZZ Plant",
        commonName: "ZZ Plant",
        plantScientificName: "Zamioculcas zamiifolia",
        plantSynonyms: ["ZZ Plant"],
        rating: 4.4,
        type: ["XL_floor_standing_plant"],
        note: "Low-light tolerant",
        bestFor: ["Low-light areas"],
        highlight: "Shiny, waxy leaves",
        description: "A low-maintenance plant with glossy, waxy leaves that can tolerate low light.",
        longDescription: "The ZZ Plant is known for its glossy, dark green leaves and ability to thrive in low-light conditions. It’s a great option for low-maintenance decor and can grow to impressive heights.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Low to moderate",
            humidityNumber: "40%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Ideal for offices and other low-light environments."
        ]
    },
    {
        id: "38",
        plantName: "Majesty Palm",
        commonName: "Majesty Palm",
        plantScientificName: "Ravenea rivularis",
        plantSynonyms: ["Majesty Palm"],
        rating: 4.5,
        type: ["XL_floor_standing_plant"],
        note: "Lush, feathery fronds",
        bestFor: ["Tropical decor"],
        highlight: "Elegant appearance",
        description: "A tall palm with lush, feathery fronds that adds a touch of elegance.",
        longDescription: "The Majesty Palm is a beautiful indoor palm with long, feathery fronds. It brings a tropical feel to any room and prefers bright, indirect light to thrive.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist but not soggy",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "High",
            humidityNumber: "60%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Regular misting helps maintain humidity levels."
        ]
    },
    {
        id: "39",
        plantName: "Sago Palm",
        commonName: "Sago Palm",
        plantScientificName: "Cycas revoluta",
        plantSynonyms: ["Sago Palm"],
        rating: 4.1,
        type: ["XL_floor_standing_plant"],
        note: "Ancient, fern-like leaves",
        bestFor: ["Formal decor"],
        highlight: "Unique foliage",
        description: "A slow-growing plant with stiff, fern-like leaves and a thick trunk.",
        longDescription: "The Sago Palm is not a true palm but has a palm-like appearance with its stiff, arching leaves. It’s an ancient plant that adds a unique, formal touch to indoor spaces.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Low to moderate",
            humidityNumber: "40%",
            toxicity: "Toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Prune dead leaves to maintain appearance."
        ]
    },
    {
        id: "40",
        plantName: "Bamboo Palm",
        commonName: "Bamboo Palm",
        plantScientificName: "Chamaedorea seifrizii",
        plantSynonyms: ["Bamboo Palm"],
        rating: 4.3,
        type: ["XL_floor_standing_plant"],
        note: "Slim, bamboo-like stems",
        bestFor: ["Indoor privacy"],
        highlight: "Elegant, dense foliage",
        description: "A palm with slim, bamboo-like stems and dense, feathery foliage.",
        longDescription: "The Bamboo Palm features slender, bamboo-like stems and dense foliage that creates a lush, tropical feel. It’s great for adding privacy and style to indoor spaces.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist but not soggy",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "High",
            humidityNumber: "60%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Regular misting can help increase humidity."
        ]
    },
    {
        id: "41",
        plantName: "Philodendron",
        commonName: "Philodendron",
        plantScientificName: "Philodendron selloum",
        plantSynonyms: ["Philodendron"],
        rating: 4.4,
        type: ["XL_floor_standing_plant"],
        note: "Large, deeply lobed leaves",
        bestFor: ["Large rooms"],
        highlight: "Lush, tropical appearance",
        description: "Features large, deeply lobed leaves that add a lush, tropical look.",
        longDescription: "The Philodendron is a versatile plant known for its dramatic, deeply lobed leaves. It thrives in bright, indirect light and can grow quite large, making it a great choice for adding a touch of the tropics to indoor spaces.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-80°F (18-27°C)",
            humidity: "Moderate to high",
            humidityNumber: "50-60%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Prune to control size and promote bushier growth."
        ]
    },
    {
        id: "42",
        plantName: "Dracaena Marginata",
        commonName: "Dracaena Marginata",
        plantScientificName: "Dracaena marginata",
        plantSynonyms: ["Dracaena Marginata"],
        rating: 4.0,
        type: ["XL_floor_standing_plant"],
        note: "Striking, narrow leaves",
        bestFor: ["Contemporary decor"],
        highlight: "Architectural appearance",
        description: "Known for its narrow, arching leaves and tree-like structure.",
        longDescription: "The Dracaena Marginata features long, narrow leaves with a striking, tree-like appearance. It’s a hardy plant that can adapt to various indoor conditions, making it perfect for modern decor.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Low to moderate",
            humidityNumber: "40-50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Rotate regularly to maintain even growth."
        ]
    },
    {
        id: "43",
        plantName: "Chinese Evergreen",
        commonName: "Chinese Evergreen",
        plantScientificName: "Aglaonema commutatum",
        plantSynonyms: ["Chinese Evergreen"],
        rating: 4.1,
        type: ["XL_floor_standing_plant"],
        note: "Attractive, variegated leaves",
        bestFor: ["Low-light areas"],
        highlight: "Colourful foliage",
        description: "Features attractive, variegated leaves with patterns of silver, green, and cream.",
        longDescription: "The Chinese Evergreen is valued for its colourful, variegated leaves and tolerance for low light conditions. It’s an excellent choice for adding colour and interest to less sunny areas.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: "50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Avoid cold drafts and fluctuating temperatures."
        ]
    },
    {
        id: "44",
        plantName: "Bamboo Palm",
        commonName: "Bamboo Palm",
        plantScientificName: "Chamaedorea seifrizii",
        plantSynonyms: ["Bamboo Palm"],
        rating: 4.3,
        type: ["XL_floor_standing_plant"],
        note: "Slender, bamboo-like stems",
        bestFor: ["Indoor privacy"],
        highlight: "Dense, lush foliage",
        description: "A palm with slender stems and dense foliage, perfect for creating a tropical look.",
        longDescription: "The Bamboo Palm features slim, bamboo-like stems and lush, dense foliage. It’s ideal for adding privacy and a touch of the tropics to indoor spaces.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist but not soggy",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "High",
            humidityNumber: "60%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Regular misting helps maintain humidity."
        ]
    },
    {
        id: "45",
        plantName: "Raphis Palm",
        commonName: "Raphis Palm",
        plantScientificName: "Rhapis excelsa",
        plantSynonyms: ["Raphis Palm"],
        rating: 4.2,
        type: ["XL_floor_standing_plant"],
        note: "Fan-shaped leaves",
        bestFor: ["Indoor tropical settings"],
        highlight: "Elegant appearance",
        description: "Known for its fan-shaped leaves and upright growth habit.",
        longDescription: "The Raphis Palm, also known as the Lady Palm, is recognized for its fan-shaped leaves and elegant form. It’s a low-maintenance plant that thrives in indoor tropical settings.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate to high",
            humidityNumber: "50-60%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Keep away from direct sunlight to prevent leaf burn."
        ]
    },
    {
        id: "46",
        plantName: "Rubber Tree",
        commonName: "Rubber Tree",
        plantScientificName: "Ficus elastica",
        plantSynonyms: ["Rubber Tree"],
        rating: 4.3,
        type: ["XL_floor_standing_plant"],
        note: "Large, glossy leaves",
        bestFor: ["Stylish decor"],
        highlight: "Bold foliage",
        description: "Features large, glossy leaves that can make a bold statement in any room.",
        longDescription: "The Rubber Tree is known for its impressive, glossy leaves and ability to grow into a large, statement plant. It’s perfect for adding a touch of style to various indoor environments.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: "50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Dust leaves regularly to maintain their shine."
        ]
    },
    {
        id: "47",
        plantName: "Pygmy Date Palm",
        commonName: "Pygmy Date Palm",
        plantScientificName: "Phoenix roebelenii",
        plantSynonyms: ["Pygmy Date Palm"],
        rating: 4.4,
        type: ["XL_floor_standing_plant"],
        note: "Compact palm with feathery leaves",
        bestFor: ["Smaller indoor spaces"],
        highlight: "Tropical appeal",
        description: "A small palm with feathery, arching leaves that can bring a touch of the tropics indoors.",
        longDescription: "The Pygmy Date Palm is ideal for smaller indoor spaces due to its compact size and feathery leaves. It adds a tropical feel to any room while being relatively easy to care for.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist but not soggy",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: "50%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Regular pruning helps maintain a neat appearance."
        ]
    },
    {
        id: "48",
        plantName: "Areca Palm",
        commonName: "Areca Palm",
        plantScientificName: "Dypsis lutescens",
        plantSynonyms: ["Areca Palm"],
        rating: 4.5,
        type: ["XL_floor_standing_plant"],
        note: "Feathery fronds",
        bestFor: ["Bright, indoor spaces"],
        highlight: "Lush foliage",
        description: "A palm with feathery fronds that creates a lush, tropical look.",
        longDescription: "The Areca Palm, also known as the Butterfly Palm, has a lush and feathery appearance. It’s perfect for bright indoor spaces and can grow to an impressive height.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist but not soggy",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "High",
            humidityNumber: "60%",
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Regular misting helps maintain humidity."
        ]
    },
    {
        id: "49",
        plantName: "Sago Palm",
        commonName: "Sago Palm",
        plantScientificName: "Cycas revoluta",
        plantSynonyms: ["Sago Palm"],
        rating: 4.1,
        type: ["XL_floor_standing_plant"],
        note: "Stiff, fern-like leaves",
        bestFor: ["Formal decor"],
        highlight: "Unique, architectural foliage",
        description: "A slow-growing plant with stiff, fern-like leaves that resembles a small palm.",
        longDescription: "The Sago Palm has a unique, architectural appearance with its stiff, fern-like leaves. It’s a great addition to formal decor and is a low-maintenance plant.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Low to moderate",
            humidityNumber: "40%",
            toxicity: "Toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Regular pruning can keep its appearance neat."
        ]
    },
    {
        id: "50",
        plantName: "Corn Plant",
        commonName: "Corn Plant",
        plantScientificName: "Dracaena fragrans",
        plantSynonyms: ["Corn Plant"],
        rating: 4.2,
        type: ["XL_floor_standing_plant"],
        note: "Striking, sword-shaped leaves",
        bestFor: ["Indoor corners"],
        highlight: "Architectural foliage",
        description: "Features striking sword-shaped leaves and a tall, upright growth habit.",
        longDescription: "The Corn Plant is known for its striking, sword-shaped leaves and tall, upright growth. It’s ideal for adding a touch of drama and sophistication to indoor corners.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: "50%",
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: [
            "Regularly dust leaves to keep them clean."
        ]
    },
    {
        id: "51",
        plantName: "Snake Plant",
        commonName: "Snake Plant",
        plantScientificName: "Sansevieria trifasciata",
        plantSynonyms: ["Snake Plant"],
        rating: 4.5,
        type: ["office_plant"],
        note: "Air purifying",
        bestFor: ["Low-light offices"],
        highlight: "Architectural leaves",
        description: "Tall, stiff leaves that grow upright, often with variegated patterns.",
        longDescription: "Snake Plants are known for their architectural, upright leaves and easy-care nature. They can thrive in low light and are excellent air purifiers, making them ideal for offices.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Low to moderate",
            humidityNumber: 40,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Avoid overwatering; they are drought-tolerant."
    },
    {
        id: "52",
        plantName: "Peace Lily",
        commonName: "Peace Lily",
        plantScientificName: "Spathiphyllum spp.",
        plantSynonyms: ["Peace Lily"],
        rating: 4.3,
        type: ["office_plant"],
        note: "Blooms indoors",
        bestFor: ["Low-light areas"],
        highlight: "White blooms",
        description: "Features dark green leaves and striking white flowers.",
        longDescription: "Peace Lilies are popular for their attractive foliage and white blooms. They thrive in low light and help purify the air, making them perfect for office environments.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Keep soil consistently moist",
            temperature: "65-80°F (18-27°C)",
            humidity: "Moderate to high",
            humidityNumber: 50 - 60,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Wipe leaves regularly to remove dust."
    },
    {
        id: "53",
        plantName: "ZZ Plant",
        commonName: "ZZ Plant",
        plantScientificName: "Zamioculcas zamiifolia",
        plantSynonyms: ["ZZ Plant"],
        rating: 4.4,
        type: ["office_plant"],
        note: "Low-light tolerant",
        bestFor: ["Low-light offices"],
        highlight: "Shiny, waxy leaves",
        description: "A low-maintenance plant with glossy, waxy leaves that can tolerate low light.",
        longDescription: "The ZZ Plant is known for its glossy, dark green leaves and ability to thrive in low-light conditions. It’s a great option for low-maintenance decor and can grow to impressive heights.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Low to moderate",
            humidityNumber: 40,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Ideal for offices and other low-light environments."
    },
    {
        id: "54",
        plantName: "Pothos",
        commonName: "Pothos",
        plantScientificName: "Epipremnum aureum",
        plantSynonyms: ["Devil’s Ivy"],
        rating: 4.5,
        type: ["office_plant"],
        note: "Easy to grow",
        bestFor: ["Hanging baskets or shelves"],
        highlight: "Trailing vines",
        description: "A trailing plant with heart-shaped, variegated leaves.",
        longDescription: "Pothos is an easy-to-grow plant with attractive, variegated leaves. It’s ideal for hanging baskets or draping over shelves and can thrive in a variety of light conditions.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow soil to dry out between waterings",
            temperature: "60-80°F (15-27°C)",
            humidity: "Low to moderate",
            humidityNumber: 40 - 50,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Prune regularly to maintain shape and promote bushier growth."
    },
    {
        id: "55",
        plantName: "Spider Plant",
        commonName: "Spider Plant",
        plantScientificName: "Chlorophytum comosum",
        plantSynonyms: ["Spider Plant"],
        rating: 4.3,
        type: ["office_plant"],
        note: "Air purifying",
        bestFor: ["Hanging baskets"],
        highlight: "Long, arching leaves",
        description: "Features long, arching leaves with green and white stripes.",
        longDescription: "Spider Plants are known for their air-purifying abilities and ease of care. They produce long, arching leaves and small plantlets that can be propagated easily.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        }
    },
    {
        id: "56",
        plantName: "Dracaena",
        commonName: "Dracaena",
        plantScientificName: "Dracaena spp.",
        plantSynonyms: ["Dracaena"],
        rating: 4.2,
        type: ["office_plant"],
        note: "Low-maintenance",
        bestFor: ["Office corners"],
        highlight: "Variegated leaves",
        description: "A versatile plant with long, variegated leaves.",
        longDescription: "Dracaena plants are popular for their striking, variegated foliage and low-maintenance nature. They can thrive in low to bright light and are perfect for office corners.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        }
    },
    {
        id: "57",
        plantName: "Rubber Plant",
        commonName: "Rubber Plant",
        plantScientificName: "Ficus elastica",
        plantSynonyms: ["Rubber Plant"],
        rating: 4.4,
        type: ["office_plant"],
        note: "Bold, glossy leaves",
        bestFor: ["Office decor"],
        highlight: "Large, attractive foliage",
        description: "Features large, glossy leaves that make a bold statement.",
        longDescription: "The Rubber Plant is known for its large, glossy leaves and upright growth habit. It’s an excellent choice for office decor, adding a touch of greenery and elegance.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Wipe leaves regularly to remove dust and maintain their shine."
    },
    {
        id: "58",
        plantName: "Parlor Palm",
        commonName: "Parlor Palm",
        plantScientificName: "Chamaedorea elegans",
        plantSynonyms: ["Parlor Palm"],
        rating: 4.2,
        type: ["office_plant"],
        note: "Compact and elegant",
        bestFor: ["Desk tops"],
        highlight: "Feather-like fronds",
        description: "A compact palm with elegant, feather-like fronds.",
        longDescription: "The Parlor Palm is a compact and elegant plant that thrives in low to moderate light. Its feather-like fronds add a touch of greenery to office desks and tabletops.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Keep soil consistently moist but not soggy",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: "Mist occasionally to increase humidity."
    },
    {
        id: "59",
        plantName: "Aloe Vera",
        commonName: "Aloe Vera",
        plantScientificName: "Aloe vera",
        plantSynonyms: ["Aloe Vera"],
        rating: 4.5,
        type: ["office_plant"],
        note: "Medicinal plant",
        bestFor: ["Sunny spots"],
        highlight: "Thick, fleshy leaves",
        description: "A succulent plant with thick, fleshy leaves filled with gel.",
        longDescription: "Aloe Vera is a popular succulent known for its medicinal properties and easy care. It thrives in bright light and can be used to soothe minor burns and skin irritations.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Low",
            humidityNumber: 40,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining cactus mix"
        },
        tips: "Ensure good drainage to prevent root rot."
    },
    {
        id: "60",
        plantName: "Cast Iron Plant",
        commonName: "Cast Iron Plant",
        plantScientificName: "Aspidistra elatior",
        plantSynonyms: ["Cast Iron Plant"],
        rating: 4.2,
        type: ["office_plant"],
        note: "Extremely durable",
        bestFor: ["Low-maintenance decor"],
        highlight: "Robust and hardy",
        description: "A very hardy plant with dark green, glossy leaves that is virtually indestructible.",
        longDescription: "The Cast Iron Plant is renowned for its extreme durability and ability to thrive in low light and neglect. It’s perfect for offices where low-maintenance plants are needed.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Low to moderate",
            humidityNumber: 40,
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: "Wipe leaves occasionally to remove dust."
    },
    {
        id: "61",
        plantName: "Dumb Cane",
        commonName: "Dumb Cane",
        plantScientificName: "Dieffenbachia spp.",
        plantSynonyms: ["Dumb Cane"],
        rating: 4.3,
        type: ["office_plant"],
        note: "Attractive foliage",
        bestFor: ["Low-light offices"],
        highlight: "Large, variegated leaves",
        description: "Features large, variegated leaves and a sturdy stem.",
        longDescription: "Dumb Cane is known for its large, variegated leaves and ability to thrive in low-light conditions. It’s a striking plant that can add a touch of the tropics to office spaces.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Keep soil consistently moist but not soggy",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Rotate regularly to maintain even growth."
    },
    {
        id: "62",
        plantName: "Lucky Bamboo",
        commonName: "Lucky Bamboo",
        plantScientificName: "Dracaena sanderiana",
        plantSynonyms: ["Lucky Bamboo"],
        rating: 4.4,
        type: ["office_plant"],
        note: "Brings good fortune",
        bestFor: ["Desktop decor"],
        highlight: "Curled or straight stems",
        description: "Features upright, green stalks that can be trained into various shapes.",
        longDescription: "Lucky Bamboo is popular for its unique, upright stalks and easy-care nature. It can be grown in water or soil and is often used in feng shui to bring good fortune.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Keep roots covered with water if grown in water; keep soil moist if grown in soil",
            temperature: "65-95°F (18-35°C)",
            humidity: "Low to moderate",
            humidityNumber: 40 - 50,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix if grown in soil"
        },
        tips: "Change water weekly if grown in water."
    },
    {
        id: "63",
        plantName: "Bird’s Nest Fern",
        commonName: "Bird’s Nest Fern",
        plantScientificName: "Asplenium nidus",
        plantSynonyms: ["Bird’s Nest Fern"],
        rating: 4.3,
        type: ["office_plant"],
        note: "Unique, wavy fronds",
        bestFor: ["Low to medium light"],
        highlight: "Rosette of wavy fronds",
        description: "Features a rosette of bright green, wavy fronds.",
        longDescription: "The Bird’s Nest Fern is known for its rosette of wavy, bright green fronds. It thrives in low to medium light and adds a touch of nature to indoor spaces.",
        careTips: {
            light: "Low to medium indirect light",
            water: "Keep soil consistently moist",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate to high",
            humidityNumber: 50 - 60,
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: "Avoid watering the centre of the rosette to prevent rot."
    },
    {
        id: "64",
        plantName: "Money Tree",
        commonName: "Money Tree",
        plantScientificName: "Pachira aquatica",
        plantSynonyms: ["Money Tree"],
        rating: 4.4,
        type: ["office_plant"],
        note: "Brings good luck",
        bestFor: ["Bright, indirect light"],
        highlight: "Braided trunk",
        description: "Known for its braided trunk and lush, green leaves.",
        longDescription: "The Money Tree is a popular indoor plant known for its braided trunk and lush foliage. It’s believed to bring good luck and prosperity and thrives in bright, indirect light.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist but not soggy",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate to high",
            humidityNumber: 50 - 60,
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: "Rotate the plant regularly to maintain even growth."
    },
    {
        id: "65",
        plantName: "Boston Fern",
        commonName: "Boston Fern",
        plantScientificName: "Nephrolepis exaltata",
        plantSynonyms: ["Boston Fern"],
        rating: 4.3,
        type: ["office_plant"],
        note: "Lush, arching fronds",
        bestFor: ["Hanging baskets or shelves"],
        highlight: "Dense, feathery foliage",
        description: "Features dense, feathery fronds that arch gracefully.",
        longDescription: "Boston Ferns are known for their lush, feathery fronds and ability to thrive in indirect light. They’re perfect for hanging baskets or shelves, adding a touch of nature to indoor spaces.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist",
            temperature: "60-75°F (15-24°C)",
            humidity: "High",
            humidityNumber: 60 - 70,
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: "Mist regularly to maintain high humidity."
    },
    {
        id: "66",
        plantName: "Jade Plant",
        commonName: "Jade Plant",
        plantScientificName: "Crassula ovata",
        plantSynonyms: ["Jade Plant"],
        rating: 4.5,
        type: ["office_plant"],
        note: "Easy to care for",
        bestFor: ["Sunny windowsills"],
        highlight: "Thick, fleshy leaves",
        description: "A succulent with thick, fleshy leaves and a tree-like growth habit.",
        longDescription: "The Jade Plant is a popular succulent known for its thick, fleshy leaves and tree-like structure. It’s easy to care for and thrives in bright light, making it ideal for sunny windowsills.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Low",
            humidityNumber: 40,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining cactus mix"
        },
        tips: "Ensure good drainage to prevent root rot."
    },
    {
        id: "67",
        plantName: "Chinese Evergreen",
        commonName: "Chinese Evergreen",
        plantScientificName: "Aglaonema commutatum",
        plantSynonyms: ["Chinese Evergreen"],
        rating: 4.4,
        type: ["office_plant"],
        note: "Attractive, variegated leaves",
        bestFor: ["Low-light offices"],
        highlight: "Colourful foliage",
        description: "Features attractive, variegated leaves with patterns of silver, green, and cream.",
        longDescription: "The Chinese Evergreen is valued for its colorful, variegated leaves and tolerance for low light conditions. It’s an excellent choice for adding color and interest to less sunny areas.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Avoid cold drafts and fluctuating temperatures."
    },
    {
        id: "68",
        plantName: "Golden Pothos",
        commonName: "Golden Pothos",
        plantScientificName: "Epipremnum aureum",
        plantSynonyms: ["Golden Pothos"],
        rating: 4.6,
        type: ["office_plant"],
        note: "Easy to propagate",
        bestFor: ["Shelves or hanging baskets"],
        highlight: "Trailing vines",
        description: "A trailing plant with heart-shaped, variegated leaves that are easy to care for and propagate.",
        longDescription: "Golden Pothos is a versatile and easy-to-care-for plant with trailing vines that make it perfect for shelves or hanging baskets. It’s highly adaptable and can thrive in various light conditions.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow soil to dry out between waterings",
            temperature: "60-80°F (15-27°C)",
            humidity: "Low to moderate",
            humidityNumber: 40 - 50,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Prune regularly to maintain shape and encourage new growth."
    },
    {
        id: "69",
        plantName: "Kentia Palm",
        commonName: "Kentia Palm",
        plantScientificName: "Howea forsteriana",
        plantSynonyms: ["Kentia Palm"],
        rating: 4.4,
        type: ["office_plant"],
        note: "Elegant fronds",
        bestFor: ["Large spaces"],
        highlight: "Graceful, arching leaves",
        description: "Features long, graceful fronds that create a tropical look.",
        longDescription: "The Kentia Palm is known for its elegant, arching fronds and ability to thrive in low to bright indirect light. It’s perfect for adding a touch of the tropics to large office spaces.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: "Wipe leaves occasionally to remove dust."
    },
    {
        id: "70",
        plantName: "Umbrella Plant",
        commonName: "Umbrella Plant",
        plantScientificName: "Schefflera arboricola",
        plantSynonyms: ["Umbrella Plant"],
        rating: 4.3,
        type: ["office_plant"],
        note: "Fast-growing",
        bestFor: ["Bright, indirect light"],
        highlight: "Umbrella-like leaf clusters",
        description: "Known for its clusters of glossy, umbrella-like leaves.",
        longDescription: "The Umbrella Plant is a fast-growing plant known for its attractive, umbrella-like clusters of glossy leaves. It thrives in bright, indirect light and adds a touch of nature to office spaces.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Prune regularly to maintain shape and promote bushier growth."
    },
    {
        id: "71",
        plantName: "Philodendron",
        commonName: "Philodendron",
        plantScientificName: "Philodendron spp.",
        plantSynonyms: ["Philodendron"],
        rating: 4.5,
        type: ["office_plant"],
        note: "Air purifying",
        bestFor: ["Low to bright indirect light"],
        highlight: "Large, heart-shaped leaves",
        description: "Features large, heart-shaped leaves and is known for its air-purifying properties.",
        longDescription: "Philodendrons are popular indoor plants known for their large, heart-shaped leaves and air-purifying abilities. They can thrive in low to bright indirect light, making them ideal for various office environments.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-80°F (18-27°C)",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Wipe leaves occasionally to remove dust."
    },
    {
        id: "72",
        plantName: "Aglaonema",
        commonName: "Aglaonema",
        plantScientificName: "Aglaonema spp.",
        plantSynonyms: ["Aglaonema"],
        rating: 4.4,
        type: ["office_plant"],
        note: "Attractive foliage",
        bestFor: ["Low-light offices"],
        highlight: "Variegated leaves",
        description: "Known for its colorful, variegated leaves and ability to thrive in low light.",
        longDescription: "Aglaonema, or Chinese Evergreen, is a popular choice for office environments due to its attractive, variegated leaves and tolerance for low-light conditions. It’s a low-maintenance plant that adds a touch of color to indoor spaces.",
        careTips: {
            light: "Low to bright indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Avoid cold drafts and fluctuating temperatures."
    },
    {
        id: "73",
        plantName: "Areca Palm",
        commonName: "Areca Palm",
        plantScientificName: "Dypsis lutescens",
        plantSynonyms: ["Areca Palm"],
        rating: 4.4,
        type: ["office_plant"],
        note: "Air purifying",
        bestFor: ["Bright, indirect light"],
        highlight: "Feathery fronds",
        description: "Features feathery, arching fronds that create a tropical look.",
        longDescription: "The Areca Palm is known for its feathery, arching fronds and air-purifying properties. It thrives in bright, indirect light and adds a touch of the tropics to office spaces.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "65-75°F (18-24°C)",
            height: "2 feet",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Non-toxic",
            soil: "Well-draining potting mix"
        },
        tips: "Wipe leaves occasionally to remove dust."
    },
    {
        id: "75",
        plantName: "Fiddle Leaf Fig",
        commonName: "Fiddle Leaf Fig",
        plantScientificName: "Ficus lyrata",
        plantSynonyms: ["Fiddle Leaf Fig"],
        rating: 4.2,
        type: ["office_plant"],
        note: "Large, violin-shaped leaves",
        bestFor: ["Bright, indirect light"],
        highlight: "Statement plant",
        description: "Features large, violin-shaped leaves and makes a bold statement.",
        longDescription: "The Fiddle Leaf Fig is known for its large, violin-shaped leaves and upright growth habit. It’s a popular choice for adding a touch of drama to office spaces with bright, indirect light.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings",
            temperature: "60-75°F (15-24°C)",
            humidity: "Moderate",
            humidityNumber: 50,
            toxicity: "Mildly toxic if ingested",
            soil: "Well-draining potting mix"
        },
        tips: "Rotate the plant regularly to ensure even growth."
    },
    {
        id: "76",
        plantName: "Marimo Moss Ball",
        commonName: "Marimo Moss Ball",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball"],
        rating: 4.7,
        type: ["mossballs"],
        note: "Unique aquatic plant",
        bestFor: ["Aquariums", "small water features"],
        highlight: "Spherical shape",
        description: "A rare, spherical form of algae that is easy to care for and can live for many years.",
        longDescription: "Marimo Moss Balls are unique, spherical algae that grow slowly and can live for many years with minimal care. They add a touch of green to aquariums and small water features, making them a fascinating addition to any space.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Gently roll the moss balls occasionally to maintain their round shape."
    },
    {
        id: "77",
        plantName: "Nano Marimo Moss Ball",
        commonName: "Nano Marimo Moss Ball",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Nano Marimo Moss Ball"],
        rating: 4.6,
        type: ["mossballs"],
        note: "Miniature version of Marimo Moss Ball",
        bestFor: ["Small aquariums", "terrariums"],
        highlight: "Tiny, spherical algae",
        description: "A smaller version of the traditional Marimo Moss Ball, perfect for tiny aquatic environments.",
        longDescription: "Nano Marimo Moss Balls are miniature versions of the traditional Marimo, ideal for small aquariums or terrariums. They add a charming touch of greenery and require very little maintenance.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "78",
        plantName: "Giant Marimo Moss Ball",
        commonName: "Giant Marimo Moss Ball",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Giant Marimo Moss Ball"],
        rating: 4.7,
        type: ["mossballs"],
        note: "Larger version of Marimo Moss Ball",
        bestFor: ["Large aquariums", "decorative bowls"],
        highlight: "Large, spherical algae",
        description: "A larger form of Marimo Moss Ball, providing a more noticeable green feature in aquatic environments.",
        longDescription: "Giant Marimo Moss Balls are larger versions of the traditional Marimo, offering a more prominent green presence in aquariums and decorative water features. They are just as easy to care for and can live for many years.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Gently roll the moss balls occasionally to maintain their round shape."
    },
    {
        id: "79",
        plantName: "Marimo Moss Ball Cluster",
        commonName: "Marimo Moss Ball Cluster",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Cluster"],
        rating: 4.5,
        type: ["mossballs"],
        note: "Group of Marimo Moss Balls",
        bestFor: ["Aquariums", "decorative bowls"],
        highlight: "Cluster of spherical algae",
        description: "A collection of Marimo Moss Balls, perfect for creating a lush, green underwater landscape.",
        longDescription: "Marimo Moss Ball Clusters are groups of Marimo algae that create a dense, green landscape in aquariums or decorative bowls. They are easy to care for and make a unique addition to any aquatic environment.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "80",
        plantName: "Marimo Moss Ball Terrarium",
        commonName: "Marimo Moss Ball Terrarium",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Terrarium"],
        rating: 4.6,
        type: ["mossballs"],
        note: "Moss balls in a glass terrarium",
        bestFor: ["Desktop decor", "small spaces"],
        highlight: "Decorative glass container",
        description: "A decorative terrarium featuring Marimo Moss Balls, adding a touch of green to small spaces.",
        longDescription: "Marimo Moss Ball Terrariums are elegant glass containers that house Marimo algae, perfect for desktops and small spaces. They require minimal care and make a fascinating, low-maintenance green addition to any room.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "81",
        plantName: "Floating Marimo Moss Ball",
        commonName: "Floating Marimo Moss Ball",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Floating Marimo Moss Ball"],
        rating: 4.5,
        type: ["mossballs"],
        note: "Floats in water",
        bestFor: ["Aquariums", "water features"],
        highlight: "Buoyant algae",
        description: "A Marimo Moss Ball that naturally floats in water, adding a dynamic element to aquariums.",
        longDescription: "Floating Marimo Moss Balls are buoyant forms of Marimo algae that float in water, creating a dynamic and interesting feature in aquariums and water displays. They are easy to care for and add a unique touch to aquatic environments.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "82",
        plantName: "Japanese Marimo Moss Ball",
        commonName: "Japanese Marimo Moss Ball",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Japanese Marimo Moss Ball"],
        rating: 4.7,
        type: ["mossballs"],
        note: "Originates from Japan",
        bestFor: ["Aquariums", "decorative bowls"],
        highlight: "Dense, green algae",
        description: "A traditional Marimo Moss Ball from Japan, known for its dense, green appearance.",
        longDescription: "Japanese Marimo Moss Balls are traditional forms of Marimo algae that originate from Japan. They are dense, green, and easy to care for, making them a perfect addition to aquariums and decorative water features.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "83",
        plantName: "Marimo Moss Ball Sculpture",
        commonName: "Marimo Moss Ball Sculpture",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Sculpture"],
        rating: 4.6,
        type: ["mossballs"],
        note: "Artistic arrangement",
        bestFor: ["Decorative purposes"],
        highlight: "Sculpted algae",
        description: "An artistic arrangement of Marimo Moss Balls, creating a living sculpture.",
        longDescription: "Marimo Moss Ball Sculptures are artistic arrangements of Marimo algae that create living sculptures. They are perfect for decorative purposes and add a unique, natural element to any space.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "84",
        plantName: "Marimo Moss Ball Aquarium",
        commonName: "Marimo Moss Ball Aquarium",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Aquarium"],
        rating: 4.7,
        type: ["mossballs"],
        note: "Complete aquarium setup",
        bestFor: ["Aquatic environments"],
        highlight: "Includes Marimo moss balls",
        description: "A complete aquarium setup featuring Marimo Moss Balls, ideal for aquatic displays.",
        longDescription: "Marimo Moss Ball Aquariums are complete setups that include Marimo algae, perfect for creating a lush, green underwater environment. They are easy to maintain and make a beautiful addition to any aquatic space.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "85",
        plantName: "Marimo Moss Ball Pendant",
        commonName: "Marimo Moss Ball Pendant",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Pendant"],
        rating: 4.5,
        type: ["mossballs"],
        note: "Wearable plant",
        bestFor: ["Jewellery", "decoration"],
        highlight: "Unique accessory",
        description: "A Marimo Moss Ball enclosed in a pendant, creating a unique, living accessory.",
        longDescription: "Marimo Moss Ball Pendants are unique accessories that feature Marimo algae enclosed in a pendant. They are perfect for jewellery or decorative purposes, adding a touch of nature to any outfit or space.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "86",
        plantName: "Marimo Moss Ball Wall Art",
        commonName: "Marimo Moss Ball Wall Art",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Wall Art"],
        rating: 4.6,
        type: ["mossballs"],
        note: "Mounted display",
        bestFor: ["Wall decor"],
        highlight: "Unique green art",
        description: "A mounted display featuring Marimo Moss Balls, creating a living piece of wall art.",
        longDescription: "Marimo Moss Ball Wall Art is a mounted display that features Marimo algae, creating a living piece of wall decor. They add a unique and natural element to any space and are easy to care for.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "87",
        plantName: "Marimo Moss Ball Aquarium Kit",
        commonName: "Marimo Moss Ball Aquarium Kit",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Aquarium Kit"],
        rating: 4.7,
        type: ["mossballs"],
        note: "All-in-one kit",
        bestFor: ["Aquatic beginners"],
        highlight: "Includes everything needed",
        description: "A complete aquarium kit that includes Marimo Moss Balls, perfect for beginners.",
        longDescription: "Marimo Moss Ball Aquarium Kits are all-in-one setups that include Marimo algae, making them ideal for beginners looking to create a lush, green aquatic environment. They are easy to maintain and provide everything needed for a beautiful display.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "88",
        plantName: "Marimo Moss Ball Necklace",
        commonName: "Marimo Moss Ball Necklace",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Necklace"],
        rating: 4.5,
        type: ["moss balls"],
        note: "Wearable plant",
        bestFor: ["Jewellery", "decoration"],
        highlight: "Living jewellery",
        description: "A necklace featuring a Marimo Moss Ball, making a unique and natural accessory.",
        longDescription: "Marimo Moss Ball Necklaces are unique pieces of jewelry that feature Marimo algae, creating a living and natural accessory. They are perfect for adding a touch of green to any outfit.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "89",
        plantName: "Marimo Moss Ball Garden",
        commonName: "Marimo Moss Ball Garden",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Garden"],
        rating: 4.6,
        type: ["moss balls"],
        note: "Multiple moss balls",
        bestFor: ["Aquariums", "decorative bowls"],
        highlight: "Green aquatic garden",
        description: "A garden of Marimo Moss Balls, creating a lush, green underwater landscape.",
        longDescription: "Marimo Moss Ball Gardens are collections of Marimo algae that create a lush, green underwater landscape. They are easy to care for and make a beautiful addition to any aquarium or decorative water feature.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "90",
        plantName: "Marimo Moss Ball Ring",
        commonName: "Marimo Moss Ball Ring",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Ring"],
        rating: 4.5,
        type: ["moss balls"],
        note: "Wearable plant",
        bestFor: ["Jewellery", "decoration"],
        highlight: "Unique ring",
        description: "A ring featuring a Marimo Moss Ball, creating a unique piece of living jewelry.",
        longDescription: "Marimo Moss Ball Rings are unique pieces of jewelry that feature Marimo algae, creating a living and natural accessory. They are perfect for adding a touch of green to any outfit.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "91",
        plantName: "Marimo Moss Ball Pet",
        commonName: "Marimo Moss Ball Pet",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Pet"],
        rating: 4.6,
        type: ["moss balls"],
        note: "Living pet",
        bestFor: ["Children", "beginners"],
        highlight: "Easy to care for",
        description: "A Marimo Moss Ball that can be kept as a pet, perfect for children and beginners.",
        longDescription: "Marimo Moss Ball Pets are living algae that can be kept as pets. They are easy to care for and make a unique, low-maintenance pet for children and beginners.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "92",
        plantName: "Marimo Moss Ball Art Piece",
        commonName: "Marimo Moss Ball Art Piece",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Art Piece"],
        rating: 4.5,
        type: ["moss balls"],
        note: "Artistic display",
        bestFor: ["Home decor", "offices"],
        highlight: "Unique green art",
        description: "An artistic display featuring Marimo Moss Balls, creating a living piece of art.",
        longDescription: "Marimo Moss Ball Art Pieces are artistic displays that feature Marimo algae, creating a living piece of art. They are perfect for home decor or office spaces, adding a unique and natural element.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "93",
        plantName: "Marimo Moss Ball Hanging Ornament",
        commonName: "Marimo Moss Ball Hanging Ornament",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Hanging Ornament"],
        rating: 4.6,
        type: ["moss balls"],
        note: "Hanging display",
        bestFor: ["Home decor", "offices"],
        highlight: "Unique hanging feature",
        description: "A hanging ornament featuring a Marimo Moss Ball, perfect for unique home or office decor.",
        longDescription: "Marimo Moss Ball Hanging Ornaments are unique decorations that feature Marimo algae, creating a living, hanging display. They are perfect for adding a touch of green to any home or office.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "94",
        plantName: "Marimo Moss Ball EcoSphere",
        commonName: "Marimo Moss Ball EcoSphere",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball EcoSphere"],
        rating: 4.7,
        type: ["moss balls"],
        note: "Self-contained ecosystem",
        bestFor: ["Home decor", "gifts"],
        highlight: "Complete ecosystem",
        description: "A self-contained ecosystem featuring Marimo Moss Balls, perfect for home decor or gifts.",
        longDescription: "Marimo Moss Ball Eco Spheres are self-contained ecosystems that feature Marimo algae. They are perfect for home decor or as unique gifts, adding a touch of green to any space.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "95",
        plantName: "Marimo Moss Ball Living Jewelry",
        commonName: "Marimo Moss Ball Living Jewelry",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Living Jewelry"],
        rating: 4.5,
        type: ["moss balls"],
        note: "Wearable plant",
        bestFor: ["Jewellery", "decoration"],
        highlight: "Unique living accessory",
        description: "A piece of jewellery featuring a Marimo Moss Ball, creating a unique living accessory.",
        longDescription: "Marimo Moss Ball Living Jewelry are unique pieces of jewelry that feature Marimo algae, creating living accessories. They are perfect for adding a touch of green to any outfit.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "96",
        plantName: "Marimo Moss Ball Lamp",
        commonName: "Marimo Moss Ball Lamp",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Lamp"],
        rating: 4.6,
        type: ["moss balls"],
        note: "Illuminated display",
        bestFor: ["Home decor", "offices"],
        highlight: "Unique green lamp",
        description: "A lamp featuring Marimo Moss Balls, creating an illuminated green display.",
        longDescription: "Marimo Moss Ball Lamps are unique lamps that feature Marimo algae, creating an illuminated green display. They are perfect for home decor or office spaces, adding a unique and natural element.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "97",
        plantName: "Marimo Moss Ball Fairy Garden",
        commonName: "Marimo Moss Ball Fairy Garden",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Fairy Garden"],
        rating: 4.7,
        type: ["moss balls"],
        note: "Miniature garden",
        bestFor: ["Home decor", "gifts"],
        highlight: "Enchanting display",
        description: "A miniature fairy garden featuring Marimo Moss Balls, perfect for home decor or as gifts.",
        longDescription: "Marimo Moss Ball Fairy Gardens are miniature gardens that feature Marimo algae, creating an enchanting display. They are perfect for home decor or as unique gifts, adding a touch of green and magic to any space.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "98",
        plantName: "Marimo Moss Ball Floating Island",
        commonName: "Marimo Moss Ball Floating Island",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Floating Island"],
        rating: 4.6,
        type: ["moss balls"],
        note: "Floating display",
        bestFor: ["Aquariums", "water features"],
        highlight: "Unique floating feature",
        description: "A floating island featuring Marimo Moss Balls, creating a unique and dynamic display.",
        longDescription: "Marimo Moss Ball Floating Islands are unique displays that feature Marimo algae, creating floating islands in aquariums or water features. They are easy to care for and add a dynamic element to any aquatic environment.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "99",
        plantName: "Marimo Moss Ball Zen Garden",
        commonName: "Marimo Moss Ball Zen Garden",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Zen Garden"],
        rating: 4.7,
        type: ["moss balls"],
        note: "Zen garden display",
        bestFor: ["Home decor", "offices"],
        highlight: "Calming display",
        description: "A Zen garden featuring Marimo Moss Balls, creating a calming and peaceful display.",
        longDescription: "Marimo Moss Ball Zen Gardens are unique displays that feature Marimo algae, creating a calming and peaceful Zen garden. They are perfect for home decor or office spaces, adding a touch of tranquility to any environment.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "100",
        plantName: "Marimo Moss Ball Art Installation",
        commonName: "Marimo Moss Ball Art Installation",
        plantScientificName: "Aegagropila linnaei",
        plantSynonyms: ["Marimo Moss Ball Art Installation"],
        rating: 4.6,
        type: ["moss balls"],
        note: "Large scale display",
        bestFor: ["Public spaces", "galleries"],
        highlight: "Unique green art",
        description: "A large-scale art installation featuring Marimo Moss Balls, perfect for public spaces or galleries.",
        longDescription: "Marimo Moss Ball Art Installations are large-scale displays that feature Marimo algae, creating unique and captivating green art. They are perfect for public spaces, galleries, or any environment looking to add a striking natural element.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Freshwater, change water every 1-2 weeks",
            temperature: "68-82°F (20-28°C)",
            humidity: "Submerged in water",
            humidityNumber: 100,
            toxicity: "Non-toxic",
            soil: "Not required, can float freely or be anchored"
        },
        tips: "Rotate the moss balls occasionally to maintain their shape."
    },
    {
        id: "101",
        plantName: "Polka Dot Plant",
        commonName: "Polka Dot Plant",
        plantScientificName: "Hypoestes",
        plantSynonyms: ["Polka dot plant", "Measles Plant", "Freckle Face Plant"],
        rating: 4.5,
        type: ["flowering", "pet-friendly", "office_plant"],
        note: "Loves humidity and medium light areas.",
        bestFor: ["Medium light areas", "windowsills", "bathrooms", "kitchens", "terrariums"],
        highlight: "Attractive colorful foliage ideal for medium light areas and perfect for beginners.",
        description: "Polka dot plants are the ideal pop of color for any houseplant collection with their brightly speckled foliage that can come in a range of colors such as white, pink, and red.",
        longDescription: "These little beauties are perfect for medium lighting situations as the lower the light is, the more vibrant the foliage is. They make a great addition to a foliage display or as a small individual plant on a window sill as pot-grown specimens tend to stay smaller and more compact. Due to their love of high humidity, the Hypoeste is perfect for a bathroom or kitchen or as a colorful addition to a terrarium. They also love their soil to stay evenly moist and will droop when thirsty, letting you know when to water, making them the perfect first-time plant for beginners. Pair this cutie with an unadorned planter to allow the foliage to shine or plant a few different colors together to create a colorful tabletop centerpiece. Please note: Polka dot plants are not known to be toxic to pets or humans.",
        careTips: {
            light: "Low to medium light is best.",
            water: "Keep soil evenly moist to the touch, be careful of overwatering. Check the soil every 2-3 days in summer.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "Likes high humidity",
            humidityNumber: 60 - 70,
            toxicity: "Non-toxic to pets and humans.",
            soil: "Well-draining, peat-based soil."
        },
        tips: "Ensure consistent watering to avoid drooping. Use a balanced liquid fertilizer diluted by half every 2 weeks during spring through summer. Ideal for grouping with other humidity-loving plants in a terrarium."
    },
    {
        id: "102",
        plantName: "Hibiscus",
        commonName: "Hibiscus",
        plantScientificName: "Hibiscus rosa-sinensis",
        plantSynonyms: ["China Rose", "Shoe Flower"],
        rating: 4.8,
        type: ["outdoor", "flowering", "pet-friendly"],
        note: "Needs full sun for best flowering.",
        bestFor: ["Outdoor gardens", "sunny balconies", "large pots"],
        highlight: "Large, colorful blooms that attract butterflies and hummingbirds.",
        description: "Hibiscus plants are known for their large, vibrant flowers that come in a variety of colors including red, yellow, pink, and white. They are a staple in tropical gardens and can bloom year-round in the right conditions.",
        longDescription: "Hibiscus plants thrive in warm, sunny environments and are often grown as ornamental plants in gardens and pots. They can grow quite large and produce a profusion of flowers, each lasting for only a day but continuously replaced by new blooms. Hibiscus plants require regular watering and fertilizing during the growing season and should be pruned to maintain their shape and encourage more blooms.",
        careTips: {
            light: "Full sun",
            water: "Keep soil moist but not waterlogged; water more frequently during hot weather.",
            temperature: "Prefers temperatures between 20°C and 30°C.",
            humidity: "Moderate to high humidity.",
            humidityNumber: 50 - 60,
            toxicity: "Non-toxic to pets and humans.",
            soil: "Well-draining soil, rich in organic matter."
        },
        tips: "Prune regularly to encourage new growth and more flowers. Protect from frost and cold drafts. Mulch around the base to retain soil moisture."
    },
    {
        id: "103",
        plantName: "Jasmine",
        commonName: "Arabian Jasmine, Mogra",
        plantScientificName: "Jasminum sambac",
        plantSynonyms: ["Mogra", "Mallika"],
        rating: 4.7,
        type: ["outdoor", "flowering", "pet-friendly"],
        note: "Strongly fragrant flowers; used in perfumes and teas.",
        bestFor: ["Outdoor gardens", "balconies", "flower beds"],
        highlight: "Highly fragrant white flowers that bloom in clusters.",
        description: "Jasmine is a popular flowering plant known for its intensely fragrant white flowers that bloom in clusters, often used in making garlands and perfumes.",
        longDescription: "Jasmine plants are cherished for their delightful fragrance and are commonly grown in gardens and pots. They thrive in warm climates and bloom profusely during the summer. The plant has a bushy growth habit and can be trained to climb on trellises or fences.",
        careTips: {
            light: "Full sun to partial shade",
            water: "Keep soil evenly moist; water regularly, especially during dry spells.",
            temperature: "Prefers temperatures between 20°C and 30°C.",
            humidity: "Moderate humidity",
            humidityNumber: 50 - 60,
            toxicity: "Non-toxic to pets and humans.",
            soil: "Well-draining, fertile soil."
        },
        tips: "Prune regularly to maintain shape and encourage more blooms. Apply a balanced fertilizer during the growing season. Mulch around the base to retain moisture and control weeds."
    },
    {
        id: "104",
        plantName: "Bougainvillea",
        commonName: "Paper Flower",
        plantScientificName: "Bougainvillea glabra",
        plantSynonyms: ["Paper Flower"],
        rating: 4.6,
        type: ["outdoor", "flowering", "hanging_plant"],
        note: "Vibrant bracts in various colors.",
        bestFor: ["Outdoor gardens", "trellises", "fences", "hanging baskets"],
        highlight: "Spectacular displays of colorful bracts that cover the plant.",
        description: "Bougainvillea is known for its vibrant bracts that come in various colors such as pink, purple, red, and white, making it a favorite for adding color to gardens.",
        longDescription: "Bougainvillea is a hardy, drought-tolerant plant that thrives in warm climates. It produces an abundance of colorful bracts, which are often mistaken for flowers. These bracts surround the small, inconspicuous flowers and create a stunning visual display.",
        careTips: {
            light: "Full sun",
            water: "Water deeply but infrequently; allow the soil to dry out between waterings.",
            temperature: "Prefers temperatures above 20°C.",
            humidity: "Low to moderate humidity",
            humidityNumber: 40 - 50,
            toxicity: "Non-toxic to pets and humans.",
            soil: "Well-draining soil, slightly acidic to neutral."
        },
        tips: "Prune regularly to control size and shape. Use a high-potassium fertilizer to promote flowering. Protect from frost."
    },
    {
        id: "105",
        plantName: "Hibiscus",
        commonName: "China Rose, Shoe Flower",
        plantScientificName: "Hibiscus rosa-sinensis",
        plantSynonyms: ["China Rose", "Shoe Flower"],
        rating: 4.8,
        type: ["outdoor", "flowering", "pet-friendly"],
        note: "Large, colorful blooms that attract pollinators.",
        bestFor: ["Outdoor gardens", "sunny balconies", "large pots"],
        highlight: "Showy flowers that come in a variety of colors.",
        description: "Hibiscus plants are known for their large, vibrant flowers that come in colors like red, yellow, pink, and white. They are popular in tropical gardens.",
        longDescription: "Hibiscus plants thrive in warm, sunny environments and are often grown as ornamental plants in gardens and pots. They can grow quite large and produce a profusion of flowers, each lasting for only a day but continuously replaced by new blooms. Hibiscus plants require regular watering and fertilizing during the growing season and should be pruned to maintain their shape and encourage more blooms.",
        careTips: {
            light: "Full sun",
            water: "Keep soil moist but not waterlogged; water more frequently during hot weather.",
            temperature: "Prefers temperatures between 20°C and 30°C.",
            humidity: "Moderate to high humidity.",
            humidityNumber: 50 - 60,
            toxicity: "Non-toxic to pets and humans.",
            soil: "Well-draining soil, rich in organic matter."
        },
        tips: "Prune regularly to encourage new growth and more flowers. Protect from frost and cold drafts. Mulch around the base to retain soil moisture."
    },
    {
        id: "106",
        plantName: "Marigold",
        CommonName: "Marigold",
        plantScientificName: "Tagetes erecta",
        plantSynonyms: ["African Marigold", "Aztec Marigold"],
        rating: 4.4,
        type: ["outdoor", "flowering", "pet-friendly"],
        note: "Bright, cheerful flowers with pest-repelling properties.",
        bestFor: ["Outdoor gardens", "borders", "companion planting"],
        highlight: "Bright orange and yellow flowers that bloom abundantly.",
        Description: "Marigolds are known for their bright, cheerful flowers and are commonly used in garden borders and as companion plants due to their pest-repelling properties.",
        longDescription: "Marigolds are easy-to-grow annuals that thrive in sunny locations. They are prized for their bright orange and yellow flowers, which bloom throughout the summer and into the fall. Marigolds are also known for their ability to repel pests, making them a great addition to vegetable gardens.",
        careTips: {
            light: "Full sun",
            water: "Water regularly; keep the soil moist but not soggy.",
            temperature: "Prefers temperatures between 18°C and 30°C.",
            humidity: "Low to moderate humidity.",
            humidityNumber: "40-50%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining soil, rich in organic matter."
        },
        tips: [
            "Deadhead regularly to encourage continuous blooming.",
            "Use as companion plants in vegetable gardens to repel pests.",
            "Fertilize lightly during the growing season."
        ]
    },
    {
        id: "107",
        plantName: "Lotus",
        CommonName: ["Lotus", "Indian Lotus"],
        plantScientificName: "Nelumbo nucifera",
        plantSynonyms: ["Sacred Lotus", "Indian Lotus"],
        rating: 4.9,
        type: ["outdoor", "flowering", "pet-friendly"],
        note: "Aquatic plant; national flower of India.",
        bestFor: ["Ponds", "water gardens", "large containers"],
        highlight: "Large, fragrant flowers that float on water.",
        Description: "The Lotus is an iconic aquatic plant known for its large, fragrant flowers that float on the surface of ponds and water gardens. It is the national flower of India.",
        longDescription: "Lotus plants are aquatic perennials that thrive in warm, sunny climates. They are prized for their beautiful, large flowers and round leaves that float on the water's surface. Lotus plants are typically grown in ponds or large containers and require consistent moisture and warmth to thrive.",
        careTips: {
            light: "Full sun",
            water: "Keep the roots submerged in water; maintain consistent water levels.",
            temperature: "Prefers temperatures between 25°C and 35°C.",
            humidity: "High humidity.",
            humidityNumber: "70-80%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Heavy clay soil, submerged in water."
        },
        tips: [
            "Plant in large containers or ponds to allow for proper growth.",
            "Fertilize with aquatic plant fertilizer during the growing season.",
            "Protect from strong winds to prevent damage to the leaves and flowers."
        ]
    },
    {
        id: 108,
        plantName: "Frangipani",
        CommonName: ["Frangipani", "Plumeria"],
        plantScientificName: "Plumeria",
        plantSynonyms: ["Temple Tree", "Champa"],
        rating: 4.7,
        type: ["outdoor", "flowering", "pet-friendly"],
        note: "Highly fragrant flowers; used in making leis.",
        bestFor: ["Outdoor gardens", "large pots", "tropical gardens"],
        highlight: "Fragrant, showy flowers in various colors.",
        Description: "Frangipani is known for its fragrant and showy flowers, which are commonly used in making Hawaiian leis. The plant produces clusters of flowers in colors such as white, yellow, pink, and red.",
        longDescription: "Frangipani is a tropical plant that thrives in warm climates. It is well-known for its beautiful and highly fragrant flowers that bloom in clusters. The plant can grow as a small tree or large shrub and is a popular choice for tropical gardens and landscapes.",
        careTips: {
            light: "Full sun",
            water: "Water regularly during the growing season; reduce watering in winter.",
            temperature: "Prefers temperatures between 20°C and 30°C.",
            humidity: "Moderate humidity.",
            humidityNumber: "50-60%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining soil."
        },
        tips: [
            "Prune to shape and remove dead branches.",
            "Protect from frost.",
            "Fertilize with a balanced fertilizer during the growing season."
        ]
    },
    {
        id: 109,
        plantName: "Indian Coral Tree",
        CommonName: "Indian Coral Tree",
        plantScientificName: "Erythrina variegata",
        plantSynonyms: ["Tiger's Claw", "Sunshine Tree"],
        rating: 4.5,
        type: ["outdoor", "flowering"],
        note: "Bright red flowers; attracts birds.",
        bestFor: ["Outdoor gardens", "parks", "large landscapes"],
        highlight: "Spectacular red flowers that attract birds and pollinators.",
        Description: "The Indian Coral Tree is known for its bright red flowers that bloom in clusters and attract birds and pollinators. It is a deciduous tree that can grow to a substantial height.",
        longDescription: "The Indian Coral Tree is a striking deciduous tree that produces bright red, tubular flowers in clusters. These flowers bloom in the spring and early summer, attracting birds and pollinators. The tree has a spreading canopy and is often used as a shade tree in parks and large gardens.",
        careTips: {
            light: "Full sun",
            water: "Water regularly during the growing season; reduce watering in winter.",
            temperature: "Prefers temperatures between 20°C and 30°C.",
            humidity: "Moderate humidity.",
            humidityNumber: "50-60%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining soil."
        },
        tips: [
            "Prune to maintain shape and remove dead branches.",
            "Provide support for young trees.",
            "Fertilize with a balanced fertilizer during the growing season."
        ]
    },
    {
        id: 110,
        plantName: "Indian Blanket Flower",
        CommonName: ["Indian Blanket Flower", "Firewheel"],
        plantScientificName: "Gaillardia pulchella",
        plantSynonyms: ["Blanket Flower", "Gaillardia"],
        rating: "4.4/5",
        type: ["outdoor", "flowering"],
        note: "Drought-tolerant; attracts butterflies.",
        bestFor: ["Outdoor gardens", "borders", "wildflower gardens"],
        highlight: "Brightly colored flowers in red and yellow.",
        Description: "The Indian Blanket Flower is known for its brightly colored flowers in shades of red and yellow. It is a drought-tolerant plant that attracts butterflies and pollinators.",
        longDescription: "The Indian Blanket Flower is a hardy, drought-tolerant plant that produces bright, daisy-like flowers in shades of red, orange, and yellow. It blooms from late spring to fall and is a favorite in wildflower gardens and borders. The plant is easy to grow and attracts butterflies and other pollinators.",
        careTips: {
            light: "Full sun",
            water: "Water regularly until established; drought-tolerant once established.",
            temperature: "Prefers temperatures between 15°C and 30°C.",
            humidity: "Low to moderate humidity.",
            humidityNumber: "30-50%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining, sandy soil."
        },
        tips: [
            "Deadhead spent flowers to encourage continuous blooming.",
            "Mulch to retain soil moisture.",
            "Fertilize lightly during the growing season."
        ]
    },
    {
        id: 111,
        plantName: "Periwinkle",
        CommonName: ["Periwinkle", "Madagascar Periwinkle"],
        plantScientificName: "Catharanthus roseus",
        plantSynonyms: ["Vinca", "Rose Periwinkle"],
        rating: "4.3/5",
        type: ["outdoor", "flowering", "pet-friendly"],
        note: "Continuous bloomer; drought-tolerant.",
        bestFor: ["Outdoor gardens", "borders", "ground cover"],
        highlight: "Continuous bloomer with flowers in various colors.",
        Description: "Periwinkle is a hardy plant known for its continuous blooming throughout the year. It produces flowers in colors such as white, pink, and purple.",
        longDescription: "Periwinkle is a versatile and hardy plant that blooms continuously throughout the year. It is drought-tolerant and can thrive in a variety of soil conditions. The plant produces small, star-shaped flowers in a range of colors, making it a popular choice for borders, ground cover, and containers.",
        careTips: {
            light: "Full sun to partial shade",
            water: "Water regularly until established; drought-tolerant once established.",
            temperature: "Prefers temperatures between 20°C and 30°C.",
            humidity: "Moderate humidity.",
            "HumidityNumber": "50-60%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining soil."
        },
        tips: [
            "Deadhead spent flowers to encourage continuous blooming.",
            "Mulch to retain soil moisture.",
            "Fertilize lightly during the growing season."
        ]
    },
    {
        id: 112,
        plantName: "Indian Marigold",
        CommonName: ["French Marigold"],
        plantScientificName: "Tagetes patula",
        plantSynonyms: ["Dwarf Marigold"],
        rating: "4.6/5",
        type: ["outdoor", "flowering", "pet-friendly"],
        note: "Bright, cheerful flowers; repels pests.",
        bestFor: ["Outdoor gardens", "borders", "companion planting"],
        highlight: "Bright yellow and orange flowers that bloom profusely.",
        Description: "Indian Marigolds are known for their bright, cheerful flowers that bloom profusely. They are easy to grow and are often used in companion planting to repel pests.",
        longDescription: "Indian Marigolds are popular annuals that produce bright, cheerful flowers in shades of yellow and orange. They bloom profusely throughout the summer and into the fall. Marigolds are easy to grow and are often used in vegetable gardens as companion plants to repel pests.",
        careTips: {
            light: "Full sun",
            water: "Water regularly; keep the soil moist but not soggy.",
            temperature: "Prefers temperatures between 18°C and 30°C.",
            humidity: "Low to moderate humidity.",
            "HumidityNumber": "40-50%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining soil, rich in organic matter."
        },
        tips: [
            "Deadhead regularly to encourage continuous blooming.",
            "Use as companion plants in vegetable gardens to repel pests.",
            "Fertilize lightly during the growing season."
        ]
    },
    {
        id: 113,
        plantName: "Golden Shower Tree",
        CommonName: ["Golden Shower Tree", "Indian Laburnum"],
        plantScientificName: "Cassia fistula",
        plantSynonyms: ["Amaltas", "Rajbrikhsha"],
        rating: "4.8/5",
        type: ["outdoor", "flowering"],
        note: "National tree of Thailand; stunning yellow flowers.",
        bestFor: ["Large gardens", "parks", "roadways"],
        highlight: "Cascading clusters of bright yellow flowers.",
        Description: "The Golden Shower Tree is renowned for its cascading clusters of bright yellow flowers that bloom in the summer. It is a popular ornamental tree in gardens and parks.",
        longDescription: "The Golden Shower Tree is a deciduous tree that produces stunning, cascading clusters of bright yellow flowers. These flowers bloom in the summer and create a spectacular display. The tree is often planted in large gardens, parks, and along roadways for its ornamental value.",
        careTips: {
            light: "Full sun",
            water: "Water regularly; reduce watering in winter.",
            temperature: "Prefers temperatures between 20°C and 35°C.",
            humidity: "Moderate humidity.",
            "HumidityNumber": "50-60%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining soil, rich in organic matter."
        },
        tips: [
            "Prune to maintain shape and remove dead branches.",
            "Protect young trees from frost.",
            "Fertilize with a balanced fertilizer during the growing season."
        ]
    },
    {
        id: 114,
        plantName: "African Violet",
        CommonName: ["African Violet"],
        plantScientificName: "Saintpaulia",
        plantSynonyms: ["Saintpaulia"],
        rating: "4.8/5",
        type: ["flowering", "office_plant"],
        note: "Compact size; ideal for windowsills.",
        bestFor: ["Indoor gardens", "small spaces"],
        highlight: "Continuous blooming in various colors.",
        Description: "African Violets are popular houseplants known for their continuous blooming and compact size. They produce small, colorful flowers in shades of purple, pink, and white.",
        longDescription: "African Violets are ideal for indoor gardening due to their compact size and ability to bloom year-round. They thrive in moderate light and require consistent moisture. The plant's vibrant flowers add a splash of color to any indoor space.",
        careTips: {
            light: "Moderate to bright indirect light",
            water: "Keep soil consistently moist; avoid getting water on leaves.",
            temperature: "Prefers temperatures between 15°C and 24°C.",
            humidity: "High humidity.",
            "HumidityNumber": "50-60%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining, acidic soil."
        },
        tips: [
            "Use a saucer with pebbles to increase humidity.",
            "Rotate the plant regularly for even growth.",
            "Fertilize every 4-6 weeks with a balanced plant food."
        ]
    },
    {
        id: 115,
        plantName: "Begonia",
        CommonName: ["Begonia"],
        plantScientificName: "Begonia x semperflorens",
        plantSynonyms: ["Wax Begonia"],
        rating: "4.7/5",
        type: ["flowering", "office_plant"],
        note: "Tolerates low light; blooms throughout the year.",
        bestFor: ["Indoor containers", "low-light areas"],
        highlight: "Continuous blooming with vibrant flowers.",
        Description: "Begonias are known for their continuous blooming and vibrant colors. They are well-suited for indoor gardening and can tolerate lower light conditions.",
        longDescription: "Begonias are popular indoor plants due to their ability to bloom throughout the year. They produce colorful flowers in shades of red, pink, and white. The plant thrives in low to moderate light and adds beauty to any indoor space.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Keep soil consistently moist; avoid waterlogging.",
            temperature: "Prefers temperatures between 15°C and 24°C.",
            humidity: "Moderate humidity.",
            "HumidityNumber": "40-50%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining, potting mix."
        },
        tips: [
            "Prune to maintain shape and promote new growth.",
            "Avoid placing in direct sunlight.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 116,
        plantName: "Periwinkle",
        CommonName: "Periwinkle, Madagascar Periwinkle",
        plantScientificName: "Catharanthus roseus",
        plantSynonyms: "Vinca, Rose Periwinkle",
        rating: "4.3/5",
        type: ["outdoor", "flowering", "pet-friendly"],
        note: "Continuous bloomer; drought-tolerant.",
        bestFor: "Outdoor gardens, borders, ground cover",
        highlight: "Continuous bloomer with flowers in various colors.",
        Description: "Periwinkle is a hardy plant known for its continuous blooming throughout the year. It produces flowers in colors such as white, pink, and purple.",
        longDescription: "Periwinkle is a versatile and hardy plant that blooms continuously throughout the year. It is drought-tolerant and can thrive in a variety of soil conditions. The plant produces small, star-shaped flowers in a range of colors, making it a popular choice for borders, ground cover, and containers.",
        careTips: {
            light: "Full sun to partial shade",
            water: "Water regularly until established; drought-tolerant once established.",
            temperature: "20°C - 30°C",
            humidity: "Moderate (50-60%)",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining"
        },
        tips: [
            "Deadhead spent flowers to encourage continuous blooming.",
            "Mulch to retain soil moisture.",
            "Fertilize lightly during the growing season."
        ]
    },
    {
        id: 117,
        plantName: "Indian Marigold",
        CommonName: "French Marigold",
        plantScientificName: "Tagetes patula",
        plantSynonyms: "Dwarf Marigold",
        rating: "4.6/5",
        type: ["outdoor", "flowering", "pet-friendly"],
        note: "Bright, cheerful flowers; repels pests.",
        bestFor: "Outdoor gardens, borders, companion planting",
        highlight: "Bright yellow and orange flowers that bloom profusely.",
        Description: "Indian Marigolds are known for their bright, cheerful flowers that bloom profusely. They are easy to grow and are often used in companion planting to repel pests.",
        longDescription: "Indian Marigolds are popular annuals that produce bright, cheerful flowers in shades of yellow and orange. They bloom profusely throughout the summer and into the fall. Marigolds are easy to grow and are often used in vegetable gardens as companion plants to repel pests.",
        careTips: {
            light: "Full sun",
            water: "Water regularly; keep the soil moist but not soggy.",
            temperature: "18°C - 30°C",
            humidity: "Low to moderate (40-50%)",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining soil, rich in organic matter."
        },
        tips: [
            "Deadhead regularly to encourage continuous blooming.",
            "Use as companion plants in vegetable gardens to repel pests.",
            "Fertilize lightly during the growing season."
        ]
    },
    {
        id: 118,
        plantName: "Golden Shower Tree",
        CommonName: "Golden Shower Tree, Indian Laburnum",
        plantScientificName: "Cassia fistula",
        plantSynonyms: "Amaltas, Rajbrikhsha",
        rating: "4.8/5",
        type: ["outdoor", "flowering"],
        note: "National tree of Thailand; stunning yellow flowers.",
        bestFor: "Large gardens, parks, roadways",
        highlight: "Cascading clusters of bright yellow flowers.",
        Description: "The Golden Shower Tree is renowned for its cascading clusters of bright yellow flowers that bloom in the summer. It is a popular ornamental tree in gardens and parks.",
        longDescription: "The Golden Shower Tree is a deciduous tree that produces stunning, cascading clusters of bright yellow flowers. These flowers bloom in the summer and create a spectacular display. The tree is often planted in large gardens, parks, and along roadways for its ornamental value.",
        careTips: {
            light: "Full sun",
            water: "Water regularly; reduce watering in winter.",
            temperature: "20°C - 35°C",
            humidity: "Moderate (50-60%)",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining soil, rich in organic matter."
        },
        tips: [
            "Prune to maintain shape and remove dead branches.",
            "Protect young trees from frost.",
            "Fertilize with a balanced fertilizer during the growing season."
        ]
    },
    {
        id: 119,
        plantName: "African Violet",
        CommonName: "African Violet",
        plantScientificName: "Saintpaulia",
        plantSynonyms: "Saintpaulia",
        rating: "4.8/5",
        type: ["flowering", "office_plant"],
        note: "Compact size; ideal for windowsills.",
        bestFor: "Indoor gardens, small spaces.",
        highlight: "Continuous blooming in various colors.",
        Description: "African Violets are popular houseplants known for their continuous blooming and compact size. They produce small, colorful flowers in shades of purple, pink, and white.",
        longDescription: "African Violets are ideal for indoor gardening due to their compact size and ability to bloom year-round. They thrive in moderate light and require consistent moisture. The plant's vibrant flowers add a splash of color to any indoor space.",
        careTips: {
            light: "Moderate to bright indirect light",
            water: "Keep soil consistently moist; avoid getting water on leaves.",
            temperature: "15°C - 24°C",
            humidity: "High (50-60%)",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining, acidic soil."
        },
        tips: [
            "Use a saucer with pebbles to increase humidity.",
            "Rotate the plant regularly for even growth.",
            "Fertilize every 4-6 weeks with a balanced plant food."
        ]
    },
    {
        id: 120,
        plantName: "Begonia",
        CommonName: "Begonia",
        plantScientificName: "Begonia x semperflorens",
        plantSynonyms: "Wax Begonia",
        rating: 4.7,
        type: ["flowering", "office_plant"],
        note: "Tolerates low light; blooms throughout the year.",
        bestFor: "Indoor containers, low-light areas.",
        highlight: "Continuous blooming with vibrant flowers.",
        Description: "Begonias are known for their continuous blooming and vibrant colors. They are well-suited for indoor gardening and can tolerate lower light conditions.",
        longDescription: "Begonias are popular indoor plants due to their ability to bloom throughout the year. They produce colorful flowers in shades of red, pink, and white. The plant thrives in low to moderate light and adds beauty to any indoor space.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Keep soil consistently moist; avoid waterlogging.",
            temperature: "15°C - 24°C",
            humidity: "Moderate (40-50%)",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining, potting mix."
        },
        tips: [
            "Prune to maintain shape and promote new growth.",
            "Avoid placing in direct sunlight.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 121,
        plantName: "Peace Lily",
        CommonName: "Peace Lily",
        plantScientificName: "Spathiphyllum",
        plantSynonyms: "Spathiphyllum",
        rating: "4.5/5",
        type: ["flowering", "office_plant"],
        note: "Low maintenance; air-purifying properties.",
        bestFor: "Indoor spaces, offices.",
        highlight: "Elegant white flowers and glossy leaves.",
        Description: "Peace Lily is appreciated for its elegant white flowers and glossy green leaves. It is a low-maintenance plant that thrives in low light and has air-purifying properties.",
        longDescription: "Peace Lily is a popular choice for indoor gardening due to its low maintenance and ability to thrive in low light conditions. It produces elegant white flowers and has glossy, dark green leaves. The plant is also known for its air-purifying qualities.",
        careTips: {
            light: "Low to moderate indirect light",
            water: "Water regularly; keep soil consistently moist.",
            temperature: "18°C - 24°C",
            humidity: "Moderate to high (60-70%)",
            Toxicity: "Mildly toxic to pets (causes irritation if ingested).",
            Soil: "Well-draining potting mix."
        },
        tips: [
            "Wipe leaves regularly to remove dust.",
            "Avoid overwatering; allow topsoil to dry slightly between waterings.",
            "Fertilize every 6-8 weeks during the growing season."
        ]
    },
    {
        id: 122,
        plantName: "Chrysanthemum",
        CommonName: "Chrysanthemum",
        plantScientificName: "Chrysanthemum x morifolium",
        plantSynonyms: "Mum",
        rating: "4.4/5",
        type: ["flowering", "office_plant"],
        note: "Popular for its colorful blooms; seasonal.",
        bestFor: "Indoor gardens, decorative pots.",
        highlight: "A wide range of vibrant flower colors.",
        Description: "Chrysanthemums are popular indoor plants known for their vibrant and diverse flower colors. They are often used for seasonal decoration.",
        longDescription: "Chrysanthemums, commonly known as mums, are well-loved for their vibrant flowers that come in a wide range of colors including yellow, red, pink, and white. They are ideal for adding seasonal color to indoor spaces and can be grown in decorative pots.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water regularly; keep soil moist but not soggy.",
            temperature: "15°C - 22°C",
            humidity: "Moderate (50-60%)",
            Toxicity: "Mildly toxic to pets (causes gastrointestinal upset if ingested).",
            Soil: "Well-draining potting mix."
        },
        tips: [
            "Deadhead spent flowers to promote new blooms.",
            "Avoid placing in direct sunlight.",
            "Fertilize every 4-6 weeks during the growing season."
        ]
    },
    {
        id: 123,
        plantName: "Kalanchoe",
        CommonName: "Kalanchoe",
        plantScientificName: "Kalanchoe blossfeldiana",
        plantSynonyms: "Flaming Katy",
        rating: "4.6/5",
        type: ["flowering", "office_plant"],
        note: "Succulent; colorful blooms.",
        bestFor: "Indoor pots, bright window sills.",
        highlight: "Bright, long-lasting flowers.",
        Description: "Kalanchoe is a succulent plant known for its bright, long-lasting flowers. It is ideal for indoor gardening and adds vibrant color to any space.",
        longDescription: "Kalanchoe is a succulent plant that produces clusters of brightly colored flowers in shades of red, pink, yellow, and orange. It thrives in bright light and is an excellent choice for adding color to indoor spaces.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow soil to dry out between waterings.",
            temperature: "15°C - 25°C",
            humidity: "Low to moderate (40-50%)",
            Toxicity: "Mildly toxic to pets (causes gastrointestinal upset if ingested).",
            Soil: "Well-draining succulent mix."
        },
        tips: [
            "Allow the soil to dry out completely between waterings.",
            "Avoid overwatering; this can lead to root rot.",
            "Fertilize with a balanced fertilizer every 4-6 weeks."
        ]
    },
    {
        id: 124,
        plantName: "Orchid",
        CommonName: "Orchid",
        plantScientificName: "Phalaenopsis",
        plantSynonyms: "Moth Orchid",
        rating: "4.9/5",
        type: ["flowering", "office_plant"],
        note: "Elegant blooms; long-lasting flowers.",
        bestFor: "Indoor display, bright rooms.",
        highlight: "Elegant, long-lasting flowers in various colors.",
        Description: "Orchids, especially Phalaenopsis, are admired for their elegant blooms and long-lasting flowers. They come in a variety of colors and patterns.",
        longDescription: "Phalaenopsis orchids are a favorite among indoor gardeners due to their striking, long-lasting blooms. They thrive in bright, indirect light and can bloom for several months. The plant requires specific care regarding watering and humidity.",
        careTips: {
            light: "Bright, indirect light",
            water: "Allow the top inch of soil to dry out between waterings.",
            temperature: "18°C - 24°C",
            humidity: "High (50-60%)",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Special orchid potting mix."
        },
        tips: [
            "Water with room-temperature water to avoid shock.",
            "Increase humidity with a pebble tray or humidifier.",
            "Fertilize with an orchid-specific fertilizer every 2-3 weeks during the growing season."
        ]
    },
    {
        id: 125,
        plantName: "Geranium",
        CommonName: "Geranium",
        plantScientificName: "Pelargonium x hortorum",
        plantSynonyms: "Zonale Geranium",
        rating: "4.5/5",
        type: ["flowering", "office_plant"],
        note: "Bright, colorful blooms; hardy.",
        bestFor: "Indoor pots, sunny windowsills.",
        highlight: "Bright, colorful flowers and fragrant foliage.",
        Description: "Geraniums are known for their vibrant, colorful flowers and aromatic foliage. They are hardy plants that can brighten up any indoor space.",
        longDescription: "Geraniums are popular for their bright, colorful blooms and fragrant leaves. They are hardy plants that can thrive in sunny indoor locations. Regular watering and occasional pruning are needed to keep them healthy and blooming.",
        careTips: {
            light: "Bright, direct light",
            water: "Water thoroughly; allow soil to dry slightly between waterings.",
            temperature: "15°C - 24°C",
            humidity: "Low to moderate (40-50%)",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining potting mix."
        },
        tips: [
            "Deadhead spent blooms to encourage new flowers.",
            "Avoid waterlogging by ensuring proper drainage.",
            "Fertilize every 4-6 weeks during the growing season."
        ]
    },

    {
        id: 126,
        plantName: "Poinsettia",
        CommonName: "Poinsettia",
        plantScientificName: "Euphorbia pulcherrima",
        plantSynonyms: "Christmas Star",
        rating: "4.6/5",
        type: ["flowering", "office_plant"],
        note: "Popular during the holiday season; bright red bracts.",
        bestFor: "Holiday decorations, festive indoor spaces.",
        highlight: "Bright red, pink, or white bracts.",
        Description: "Poinsettias are commonly associated with the holiday season due to their bright, colorful bracts. They are popular for decorating indoor spaces during the festive period.",
        longDescription: "Poinsettias are renowned for their bright, colorful bracts that can be red, pink, or white. They are popular indoor plants during the holiday season and are often used for festive decorations. The plant requires specific care to maintain its vibrant color.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil evenly moist; avoid waterlogging.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "Moderate humidity",
            "Humidity Number": "50-60%",
            Toxicity: "Mildly toxic to pets (causes mild gastrointestinal upset if ingested).",
            Soil: "Well-draining potting mix."
        },
        tips: [
            "Avoid drafts and sudden temperature changes.",
            "Fertilize with a balanced fertilizer every 6-8 weeks during the growing season.",
            "Pinch back new growth to encourage bushier plants."
        ]
    },
    {
        id: 127,
        plantName: "Rose",
        CommonName: "Rose",
        plantScientificName: "Rosa spp.",
        plantSynonyms: "Rosa",
        rating: "4.9/5",
        type: ["flowering", "XL_floor_standing_plant", "office_plant"],
        note: "Highly fragrant flowers; numerous color varieties.",
        bestFor: "Indoor pots, bright windowsills, gardens.",
        highlight: "Symbolic flowers with rich colors and fragrances.",
        Description: "Roses are highly valued for their beautiful, fragrant flowers. They come in a wide range of colors and sizes, making them perfect for indoor pots or garden settings.",
        longDescription: "Roses are one of the most popular and beloved flowering plants. They produce large, fragrant blooms in various colors, including red, pink, yellow, and white. They require plenty of light and regular care to thrive indoors.",
        careTips: {
            light: "Bright, direct light",
            water: "Water regularly; keep soil moist but not waterlogged.",
            temperature: "Prefers temperatures between 15°C and 26°C.",
            humidity: "Moderate humidity",
            "Humidity Number": "40-50%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining, rich soil."
        },
        tips: [
            "Prune regularly to encourage new growth.",
            "Remove spent flowers to promote continuous blooming.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 128,
        plantName: "Mogra",
        CommonName: "Arabian Jasmine",
        plantScientificName: "Jasminum sambac",
        plantSynonyms: "Arabian Jasmine",
        rating: "4.7/5",
        type: ["flowering", "pet-friendly", "office_plant"],
        note: "Intensely fragrant white flowers.",
        bestFor: "Indoor pots, bright rooms.",
        highlight: "Highly fragrant, beautiful white flowers.",
        Description: "Mogra is known for its intensely fragrant white flowers. It is often grown in pots indoors and can also be used in gardens.",
        longDescription: "Mogra, or Arabian Jasmine, is a popular indoor plant in India due to its intensely fragrant white flowers. The plant is often used in religious ceremonies and for making garlands. It thrives in bright, indirect light and needs regular watering.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water regularly; keep soil moist.",
            temperature: "Prefers temperatures between 20°C and 30°C.",
            humidity: "High humidity",
            "Humidity Number": "60-70%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining, fertile soil."
        },
        tips: [
            "Provide a support structure for climbing.",
            "Prune after flowering to maintain shape.",
            "Fertilize every 6-8 weeks during the growing season."
        ]
    },
    {
        id: 129,
        plantName: "Zendu",
        CommonName: "Marigold",
        plantScientificName: "Tagetes erecta",
        plantSynonyms: "African Marigold",
        rating: "4.6/5",
        type: ["flowering", "pet-friendly", "office_plant"],
        note: "Bright, vibrant flowers; easy to grow.",
        bestFor: "Indoor pots, gardens.",
        highlight: "Bright, vibrant flowers that are easy to grow.",
        Description: "Zendu, or Marigold, is known for its bright and vibrant flowers. It is easy to grow and maintain, making it a popular choice for indoor and outdoor gardening.",
        longDescription: "Marigolds, known locally as Zendu, produce bright and cheerful flowers that come in shades of yellow and orange. They are easy to grow and are often used in gardens and for religious purposes. Marigolds thrive in bright light and require regular watering.",
        careTips: {
            light: "Bright, direct light",
            water: "Water regularly; keep soil moist but not soggy.",
            temperature: "Prefers temperatures between 15°C and 30°C.",
            humidity: "Moderate humidity",
            "Humidity Number": "40-50%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining soil."
        },
        tips: [
            "Deadhead spent flowers to promote new blooms.",
            "Avoid overwatering to prevent root rot.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 130,
        plantName: "Shewanti",
        CommonName: "Chrysanthemum",
        plantScientificName: "Chrysanthemum indicum",
        plantSynonyms: "Indian Chrysanthemum",
        rating: "4.5/5",
        type: ["flowering", "office_plant"],
        note: "Seasonal blooms; various colors.",
        bestFor: "Indoor pots, seasonal displays.",
        highlight: "Bright, colorful flowers in various shades.",
        Description: "Shewanti, or Chrysanthemum, is known for its bright and colorful flowers that bloom seasonally. It is perfect for indoor pots and adds a splash of color to any room.",
        longDescription: "Shewanti, or Indian Chrysanthemum, produces bright and colorful blooms in shades of yellow, pink, red, and white. They are popular for indoor gardening and are often used for decorative purposes during festivals. The plant requires bright light and regular watering.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water regularly; keep soil moist but not waterlogged.",
            temperature: "Prefers temperatures between 15°C and 25°C.",
            humidity: "Moderate humidity",
            "Humidity Number": "40-50%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining, fertile soil."
        },
        tips: [
            "Prune to shape and remove spent flowers.",
            "Avoid placing in direct sunlight.",
            "Fertilize every 4-6 weeks during the growing season."
        ]
    },
    {
        id: 131,
        plantName: "Bougainvillea",
        CommonName: "Bougainvillea",
        plantScientificName: "Bougainvillea glabra",
        plantSynonyms: "Paperflower",
        rating: "4.8/5",
        type: ["flowering", "XL_floor_standing_plant", "office_plant"],
        note: "Vibrant bracts; drought-tolerant.",
        bestFor: "Indoor trellises, bright rooms.",
        highlight: "Colorful, paper-like bracts in various shades.",
        Description: "Bougainvillea is known for its vibrant, paper-like bracts that come in various colors such as pink, purple, red, and white. It is a drought-tolerant plant that thrives in bright light.",
        longDescription: "Bougainvillea is a popular ornamental plant known for its colorful and vibrant bracts that look like paper. It is well-suited for indoor gardening if provided with adequate light. Bougainvillea can be trained to climb on trellises or grown as a bush.",
        careTips: {
            light: "Bright, direct light",
            water: "Water when the soil is dry; avoid overwatering.",
            temperature: "Prefers temperatures between 18°C and 30°C.",
            humidity: "Low to moderate humidity",
            "Humidity Number": "30-50%",
            Toxicity: "Non-toxic to pets and humans.",
            Soil: "Well-draining soil."
        },
        tips: [
            "Prune to maintain shape and encourage new growth.",
            "Avoid excessive watering to prevent root rot.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 132,
        scientificName: "Peperomia angulata",
        commonNames: ["Beetle Peperomia", "Peperomia quadrangularis"],
        highlights: [
            "Delightful looking foliage",
            "Quick Growing",
            "Great Table Top Plant",
            "Plant Level: Medium"
        ],
        description: "If you're looking for a fresh pop of green with interesting patterned foliage, the Beetle Peperomia is a great choice. Its oval leaves feature yellow and green stripes on an orangey-red stem. The plant grows upright but cascades down as the stems get heavy, creating a striking effect.",
        careInstructions: {
            water: "Let the top layer of soil dry out (about 1cm) before watering again. Reduce watering in winter.",
            light: "Thrives in bright indirect light but will tolerate medium lighting. Avoid direct sunlight.",
            humidity: "Average household humidity is suitable.",
            fertilize: "Use diluted organic feed every two weeks in spring and summer. Stop feeding during winter."
        }
    },
    {
        id: 133,
        scientificName: "Lepismium cruciforme",
        commonName: "Hurricane Cactus",
        highlights: [
            "Waterwise",
            "Beautiful and unique coloration",
            "Undemanding and tolerates neglect",
            "Plant Level: Easy"
        ],
        description: "The Hurricane Cactus, native to the Atlantic forests of Argentina, Brazil, and Paraguay, is a succulent that thrives indoors. It’s unfussy with watering and shows fascinating color changes in bright light. It also produces delicate white-pink blooms during its flowering season.",
        careInstructions: {
            water: "Check the top layer of soil (approx 3cm). Once this is dry, it's time to water. Do not let the roots dry out. Soak your plant, let excess water run off, and ensure that it isn't waterlogged.",
            light: "Prefers dappled shade or filtered sunlight. Do not give the plant full sunlight as this would be too intense.",
            humidity: "Normal room humidity should be fine, no adjustments required.",
            fertilize: "Feed with diluted, well-balanced, organic fertilizer during its growing season (spring through summer) once every 2 weeks. During the dormancy period, cut back to once a month."
        }
    },
    {
        id: 134,
        scientificName: "Philodendron hederaceum 'brasil'",
        synonyms: ["Heart Leaf Brasil", "Philodendron Brasil"],
        highlights: [
            "Grows long branches up to ±1.5 meters or more",
            "Tolerates Extreme Neglect",
            "Trailing Plant with patterned leaves",
            "Medium to Bright Indirect light",
            "Plant Level: Easy"
        ],
        description: "A fast-growing cascading plant that likes to vine around objects or trail off shelves. Much loved for its typically green-yellow leaf variegation, Philodendron Brasils are extremely rewarding plants which couldn't be easier to keep. They will tolerate a lot of neglect, including underwatering, poor soil, and low light.",
        careInstructions: {
            water: "Dip your Moss Ball in a bowl of water for 10 minutes, once a week.",
            light: "Low to bright indirect light.",
            humidity: "No adjustments to humidity required.",
            fertilize: "Add a balanced liquid fertilizer to your water every 2 weeks, reduce to monthly in winter."
        }
    },
    {
        id: 135,
        scientificName: "Cissus rhombifolia Ellen Danica",
        synonyms: ["Grape Ivy", "Grape Ivy 'Ellen Danica'", "Oak Leaf Ivy"],
        highlights: [
            "Oak leaf-shaped green foliage",
            "Fast-growing houseplant",
            "Makes for a fantastic hanging plant",
            "Can handle a variety of conditions",
            "Plant Level: Easy"
        ],
        description: "The Grape Ivy is a fantastic plant for adding extra texture and color to your interior decor with its oak-shaped deep green rambling vines. It's highly tolerant of various lighting conditions, watering, and humidity levels, making it ideal for many locations in your home and office space.",
        careInstructions: {
            water: "Check soil weekly for dryness during warmer months. Once completely dry, water thoroughly. Reduce watering frequency in winter.",
            light: "Bright, indirect light, keep away from direct sunlight. Can handle lower lighting conditions.",
            humidity: "Normal room humidity is fine.",
            fertilize: "A good quality balanced feed every two weeks, Spring to Autumn. Avoid feeding in winter."
        }
    },
    {
        id: 136,
        scientificName: "Ficus benjamina",
        synonyms: ["Weeping Fig"],
        highlights: [
            "Lovely delicate foliage",
            "Makes for a beautiful display",
            "Can tolerate a variety of lighting conditions",
            "Relatively easy care"
        ],
        description: "The Weeping Fig tree with its deep green loose foliage and long trailing stems has an unusual yet classical look when tied into a Mossball. Benjaminas are some of the easiest indoor plants with an appetite for any light condition.",
        careInstructions: {
            water: "Dip your Moss Ball in a bowl of water for 10 minutes, once a week.",
            light: "Low to bright indirect light.",
            humidity: "No adjustments to humidity required.",
            fertilize: "Add a balanced liquid fertilizer to your water every 2 weeks, reduce to monthly in winter."
        }
    },
    {
        id: 137,
        plantName: "Spider Plant",
        commonName: "Spider Plant",
        scientificName: "Chlorophytum comosum",
        synonyms: ["Ribbon Plant", "Airplane Plant"],
        rating: "4.8/5",
        type: ["hanging_plant", "pet-friendly", "office_plant"],
        note: "Easy to care for; produces baby plants.",
        bestFor: ["Hanging baskets", "indoor pots"],
        highlight: "Arching green leaves with white stripes; produces plantlets.",
        description: "The Spider Plant is a popular hanging plant known for its arching green leaves with white stripes. It produces baby plants, or 'spiderettes,' which can be propagated easily.",
        longDescription: "Chlorophytum comosum, commonly known as the Spider Plant, is a versatile and low-maintenance hanging plant. Its arching leaves are green with white stripes, creating a striking visual effect. Spider Plants are known for their ability to produce plantlets, which can be easily propagated. They thrive in bright, indirect light and are non-toxic to pets.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water regularly; keep soil evenly moist.",
            temperature: "Prefers temperatures between 15°C and 25°C.",
            humidity: "Moderate to high humidity.",
            humidityNumber: "50-60%",
            toxicity: "Non-toxic to pets.",
            soil: "Well-draining soil."
        },
        tips: [
            "Mist regularly to increase humidity.",
            "Trim brown tips to maintain appearance.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 138,
        plantName: "English Ivy",
        commonName: "English Ivy",
        scientificName: "Hedera helix",
        synonyms: ["Common Ivy"],
        rating: "4.5/5",
        type: ["hanging_plant", "pet-friendly"],
        note: "Trailing vines; air-purifying.",
        bestFor: ["Hanging baskets", "indoor pots"],
        highlight: "Trailing vines with evergreen foliage.",
        description: "English Ivy is a classic hanging plant with trailing vines and evergreen foliage. It's known for its air-purifying qualities and can thrive in various light conditions.",
        longDescription: "Hedera helix, commonly known as English Ivy, is a popular hanging plant that features trailing vines and evergreen leaves. English Ivy is an excellent choice for hanging baskets and can also be used as a ground cover. It is known for its air-purifying properties and can adapt to different light conditions, making it a versatile addition to indoor spaces.",
        careTips: {
            light: "Bright, indirect light to low light",
            water: "Water moderately; allow soil to dry out between waterings.",
            temperature: "Prefers temperatures between 10°C and 20°C.",
            humidity: "Moderate to high humidity.",
            humidityNumber: "40-60%",
            toxicity: "Non-toxic to pets.",
            soil: "Well-draining soil."
        },
        tips: [
            "Trim regularly to control growth and maintain shape.",
            "Mist leaves to increase humidity.",
            "Fertilize every 6-8 weeks during the growing season."
        ]
    },
    {
        id: 139,
        plantName: "Boston Fern",
        commonName: "Boston Fern",
        scientificName: "Nephrolepis exaltata",
        synonyms: ["Sword Fern"],
        rating: "4.7/5",
        type: ["hanging_plant", "pet-friendly", "office_plant"],
        note: "Air-purifying; lush green fronds.",
        bestFor: ["Hanging baskets", "indoor pots"],
        highlight: "Arching, feathery fronds that purify the air.",
        description: "Boston Fern is a lush, air-purifying plant with arching, feathery fronds. It thrives in high humidity and indirect light, making it ideal for hanging baskets.",
        longDescription: "Nephrolepis exaltata, commonly known as Boston Fern, is a classic choice for hanging baskets due to its lush, arching fronds. This plant is known for its air-purifying qualities and preference for high humidity. Boston Ferns thrive in indirect light and are non-toxic to pets, making them perfect for indoor environments.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist; do not let dry out.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "High humidity.",
            humidityNumber: "60-70%",
            toxicity: "Non-toxic to pets.",
            soil: "Well-draining, rich soil."
        },
        tips: [
            "Mist leaves daily to maintain humidity.",
            "Trim dead fronds to encourage new growth.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 140,
        plantName: "Pothos",
        commonName: "Devil's Ivy",
        scientificName: "Epipremnum aureum",
        synonyms: ["Golden Pothos", "Ceylon Creeper"],
        rating: "4.6/5",
        type: ["hanging_plant", "pet-friendly", "office_plant"],
        note: "Easy to grow; variegated leaves.",
        bestFor: ["Hanging baskets", "indoor pots"],
        highlight: "Heart-shaped, variegated leaves that are easy to care for.",
        description: "Pothos is a hardy, easy-to-grow plant with heart-shaped, variegated leaves. It is perfect for hanging baskets and indoor pots, thriving in various light conditions.",
        longDescription: "Epipremnum aureum, commonly known as Pothos or Devil's Ivy, is a popular hanging plant known for its heart-shaped, variegated leaves. Pothos is very easy to care for and can tolerate a range of light conditions. Its trailing vines make it perfect for hanging baskets, and it is non-toxic to pets.",
        careTips: {
            light: "Low to bright, indirect light",
            water: "Water when the top inch of soil is dry.",
            temperature: "Prefers temperatures between 18°C and 30°C.",
            humidity: "Moderate humidity.",
            humidityNumber: "40-60%",
            toxicity: "Non-toxic to pets.",
            soil: "Well-draining soil."
        },
        tips: [
            "Trim vines to control length and promote bushier growth.",
            "Wipe leaves to remove dust and promote better photosynthesis.",
            "Fertilize every 2-3 months during the growing season."
        ]
    },
    {
        id: 141,
        plantName: "String of Pearls",
        commonName: "String of Pearls",
        scientificName: "Senecio rowleyanus",
        synonyms: ["String-of-Beads", "Rosary Vine"],
        rating: "4.3/5",
        type: ["hanging_plant", "pet-friendly"],
        note: "Unique bead-like leaves; drought-tolerant.",
        bestFor: ["Hanging baskets", "indoor pots"],
        highlight: "Unique, bead-like leaves that trail elegantly.",
        description: "String of Pearls is a striking hanging plant with unique, bead-like leaves that trail beautifully. It is drought-tolerant and perfect for hanging baskets.",
        longDescription: "Senecio rowleyanus, commonly known as String of Pearls, is a distinctive hanging plant with bead-like leaves that create a cascading effect. This succulent plant is drought-tolerant and requires minimal care, making it ideal for hanging baskets and indoor pots. String of Pearls thrives in bright, indirect light and well-draining soil.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water sparingly; allow soil to dry out between waterings.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "Low to moderate humidity.",
            humidityNumber: "30-50%",
            toxicity: "Non-toxic to pets.",
            soil: "Well-draining, sandy soil."
        },
        tips: [
            "Avoid overwatering to prevent root rot.",
            "Prune to control length and encourage fuller growth.",
            "Fertilize sparingly, once or twice during the growing season."
        ]
    },
    {
        id: 141,
        plantName: "Burro's Tail",
        CommonName: "Burro's Tail",
        plantScientificName: "Sedum morganianum",
        plantSynonyms: ["Donkey's Tail"],
        rating: 4.4,
        type: ["hanging_plant", "pet-friendly"],
        note: "Succulent with trailing stems; drought-tolerant.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Trailing stems with fleshy, blue-green leaves.",
        Description: "Burro's Tail is a succulent with long, trailing stems covered in fleshy, blue-green leaves. It is drought-tolerant and ideal for hanging baskets.",
        longDescription: "Sedum morganianum, commonly known as Burro's Tail or Donkey's Tail, is a popular succulent known for its trailing stems covered in fleshy, blue-green leaves. This plant is perfect for hanging baskets and requires minimal watering, making it an excellent choice for busy plant owners. Burro's Tail thrives in bright, indirect light and well-draining soil.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water sparingly; allow soil to dry out between waterings.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "Low humidity.",
            humidityNumber: "30-50%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining, sandy soil."
        },
        tips: [
            "Handle with care to avoid breaking the delicate leaves.",
            "Fertilize sparingly, once or twice during the growing season.",
            "Provide good air circulation to prevent rot."
        ]
    },
    {
        id: 142,
        plantName: "String of Hearts",
        CommonName: "String of Hearts",
        plantScientificName: "Ceropegia woodii",
        plantSynonyms: ["Rosary Vine", "Chain of Hearts"],
        rating: "4.6/5",
        type: ["hanging_plant", "pet-friendly"],
        note: "Heart-shaped leaves; easy to propagate.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Trailing vines with heart-shaped leaves and delicate flowers.",
        Description: "String of Hearts is a charming hanging plant with trailing vines and heart-shaped leaves. It is easy to propagate and ideal for hanging baskets.",
        longDescription: "Ceropegia woodii, commonly known as String of Hearts or Rosary Vine, is a delightful hanging plant with trailing vines adorned with heart-shaped leaves. The plant is easy to propagate and produces small, tubular flowers. String of Hearts thrives in bright, indirect light and well-draining soil, making it perfect for hanging baskets and indoor pots.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water sparingly; allow soil to dry out between waterings.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "Moderate humidity.",
            "Humidity Number": "40-50%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining soil."
        },
        tips: [
            "Propagate by placing cuttings in water or directly in soil.",
            "Avoid overwatering to prevent root rot.",
            "Fertilize every 4-6 weeks during the growing season."
        ]
    },
    {
        id: 143,
        plantName: "Maidenhair Fern",
        CommonName: "Maidenhair Fern",
        plantScientificName: "Adiantum raddianum",
        plantSynonyms: ["Delta Maidenhair"],
        rating: "4.5/5",
        type: ["hanging_plant", "pet-friendly", "office_plant"],
        note: "Delicate fronds; high humidity lover.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Delicate, feathery fronds that thrive in high humidity.",
        Description: "Maidenhair Fern is known for its delicate, feathery fronds that add a touch of elegance to any space. It thrives in high humidity and indirect light.",
        longDescription: "Adiantum raddianum, commonly known as Maidenhair Fern, is a beautiful plant with delicate, feathery fronds. This fern thrives in high humidity and indirect light, making it perfect for hanging baskets and indoor pots. Maidenhair Ferns require consistent moisture and benefit from regular misting to maintain humidity.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist; do not let dry out.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "High humidity.",
            "Humidity Number": "60-70%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining, rich soil."
        },
        tips: [
            "Mist leaves regularly to maintain humidity.",
            "Avoid direct sunlight to prevent frond damage.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 144,
        plantName: "String of Bananas",
        CommonName: "String of Bananas",
        plantScientificName: "Senecio radicans",
        plantSynonyms: ["Banana Vine"],
        rating: "4.3/5",
        type: ["hanging_plant", "pet-friendly"],
        note: "Unique banana-shaped leaves; drought-tolerant.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Trailing vines with banana-shaped leaves.",
        Description: "String of Bananas is a unique hanging plant with trailing vines adorned with banana-shaped leaves. It is drought-tolerant and perfect for hanging baskets.",
        longDescription: "Senecio radicans, commonly known as String of Bananas, is a fascinating succulent with trailing vines and banana-shaped leaves. This plant is drought-tolerant and thrives in bright, indirect light, making it ideal for hanging baskets and indoor pots. String of Bananas is easy to care for and adds a playful touch to any plant collection.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water sparingly; allow soil to dry out between waterings.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "Low to moderate humidity.",
            "Humidity Number": "30-50%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining, sandy soil."
        },
        tips: [
            "Handle with care to avoid breaking the delicate leaves.",
            "Fertilize sparingly, once or twice during the growing season.",
            "Provide good air circulation to prevent rot."
        ]
    },
    {
        id: 145,
        plantName: "Heartleaf Philodendron",
        CommonName: "Heartleaf Philodendron",
        plantScientificName: "Philodendron hederaceum",
        plantSynonyms: ["Sweetheart Plant", "Philodendron scandens"],
        rating: "4.6/5",
        type: ["hanging_plant", "pet-friendly", "office_plant"],
        note: "Heart-shaped leaves; easy to grow.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Trailing vines with heart-shaped leaves.",
        Description: "Heartleaf Philodendron is a popular hanging plant with trailing vines and heart-shaped leaves. It is easy to grow and thrives in various light conditions.",
        longDescription: "Philodendron hederaceum, commonly known as Heartleaf Philodendron, is a versatile and low-maintenance hanging plant. Its trailing vines and heart-shaped leaves make it a favorite for hanging baskets and indoor pots. This plant thrives in bright, indirect light but can also tolerate low light conditions. It is non-toxic to pets and easy to care for.",
        careTips: {
            light: "Bright, indirect light to low light",
            water: "Water when the top inch of soil is dry.",
            temperature: "Prefers temperatures between 18°C and 30°C.",
            humidity: "Moderate humidity.",
            "Humidity Number": "40-60%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining soil."
        },
        tips: [
            "Trim vines to control length and promote bushier growth.",
            "Wipe leaves to remove dust and promote better photosynthesis.",
            "Fertilize every 2-3 months during the growing season."
        ]
    },
    {
        id: 146,
        plantName: "Jade Pothos",
        CommonName: "Jade Pothos",
        plantScientificName: "Epipremnum aureum 'Jade'",
        plantSynonyms: ["Devil's Ivy", "Money Plant"],
        rating: "4.7/5",
        type: ["hanging_plant", "office_plant"],
        note: "Hardy, low-maintenance; excellent air purifier.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Deep green, glossy leaves.",
        Description: "Jade Pothos is a popular houseplant known for its deep green, glossy leaves and ease of care. It is a hardy, low-maintenance plant that is excellent for purifying indoor air.",
        longDescription: "Epipremnum aureum 'Jade', commonly known as Jade Pothos or Devil's Ivy, is a versatile and hardy plant that thrives in a variety of indoor conditions. Its deep green, glossy leaves make it a favorite for hanging baskets and indoor pots. Jade Pothos is an excellent air purifier and can tolerate low light, making it perfect for offices and homes.",
        careTips: {
            light: "Low to bright, indirect light",
            water: "Water when the top inch of soil is dry.",
            temperature: "Prefers temperatures between 15°C and 30°C.",
            humidity: "Moderate humidity.",
            "Humidity Number": "40-60%",
            Toxicity: "Toxic to pets if ingested.",
            Soil: "Well-draining soil."
        },
        tips: [
            "Trim vines to control growth and promote bushiness.",
            "Wipe leaves occasionally to remove dust.",
            "Fertilize every 2-3 months during the growing season."
        ]
    },
    {
        id: 147,
        plantName: "Arrowhead Plant",
        CommonName: "Arrowhead Plant",
        plantScientificName: "Syngonium podophyllum 'Neon'",
        plantSynonyms: ["Arrowhead Vine", "Goosefoot Plant"],
        rating: "4.5/5",
        type: ["hanging_plant", "office_plant", "pet-friendly"],
        note: "Vibrant neon-pink leaves; easy to grow.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Neon-pink, arrowhead-shaped leaves.",
        Description: "The Arrowhead Plant is known for its vibrant neon-pink, arrowhead-shaped leaves. It is easy to grow and perfect for hanging baskets and indoor pots.",
        longDescription: "Syngonium podophyllum 'Neon', commonly known as Arrowhead Plant or Arrowhead Vine, is a striking plant with neon-pink, arrowhead-shaped leaves. This plant is easy to care for and thrives in various indoor conditions, making it a great choice for hanging baskets and indoor pots. It is non-toxic to pets and helps purify the air.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil evenly moist, but not waterlogged.",
            temperature: "Prefers temperatures between 18°C and 27°C.",
            humidity: "High humidity.",
            "Humidity Number": "50-70%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining, rich soil."
        },
        tips: [
            "Mist regularly to maintain humidity.",
            "Prune to control growth and encourage fuller plants.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 148,
        plantName: "Hoya Carnosa",
        CommonName: "Wax Plant",
        plantScientificName: "Hoya carnosa",
        plantSynonyms: ["Porcelain Flower"],
        rating: "4.6/5",
        type: ["hanging_plant", "pet-friendly"],
        note: "Thick, waxy leaves; fragrant flowers.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Thick, waxy leaves with fragrant, star-shaped flowers.",
        Description: "Hoya Carnosa is known for its thick, waxy leaves and clusters of fragrant, star-shaped flowers. It is ideal for hanging baskets and indoor pots.",
        longDescription: "Hoya carnosa, commonly known as Wax Plant or Porcelain Flower, is a popular hanging plant known for its thick, waxy leaves and beautiful, fragrant, star-shaped flowers. This plant is easy to care for and thrives in bright, indirect light. It is non-toxic to pets and makes a great addition to any indoor plant collection.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water when the top inch of soil is dry.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "Moderate to high humidity.",
            "Humidity Number": "50-60%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining soil."
        },
        tips: [
            "Avoid overwatering to prevent root rot.",
            "Provide a trellis or support for climbing.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 149,
        plantName: "Peace Lily 'Sebastian'",
        CommonName: "Peace Lily",
        plantScientificName: "Spathiphyllum 'Sebastian'",
        plantSynonyms: ["White Sail Plant"],
        rating: "4.7/5",
        type: ["hanging_plant", "office_plant"],
        note: "White, sail-like flowers; excellent air purifier.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "White, sail-like flowers and glossy, dark green leaves.",
        Description: "Peace Lily 'Sebastian' is known for its elegant white, sail-like flowers and glossy, dark green leaves. It is an excellent air purifier and thrives in low light.",
        longDescription: "Spathiphyllum 'Sebastian', commonly known as Peace Lily, is a popular houseplant recognized for its white, sail-like flowers and glossy, dark green leaves. This plant is an excellent air purifier and can thrive in low light conditions, making it ideal for offices and homes. Peace Lilies prefer consistently moist soil and high humidity.",
        careTips: {
            light: "Low to bright, indirect light",
            water: "Keep soil consistently moist, but not waterlogged.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "High humidity.",
            "Humidity Number": "50-60%",
            Toxicity: "Toxic to pets if ingested.",
            Soil: "Well-draining, rich soil."
        },
        tips: [
            "Wipe leaves to remove dust and promote better photosynthesis.",
            "Mist regularly to maintain humidity.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 150,
        plantName: "Natal Ivy",
        CommonName: "Natal Ivy",
        plantScientificName: "Senecio macroglossus",
        plantSynonyms: ["Wax Ivy", "German Ivy"],
        rating: "4.5/5",
        type: ["hanging_plant", "pet-friendly"],
        note: "Fast-growing vine; bright green leaves.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Bright green, ivy-like leaves.",
        Description: "Natal Ivy is a fast-growing vine with bright green, ivy-like leaves. It is ideal for hanging baskets and indoor pots and is non-toxic to pets.",
        longDescription: "Senecio macroglossus, commonly known as Natal Ivy or Wax Ivy, is a fast-growing vine with bright green, ivy-like leaves. This plant is easy to care for and thrives in bright, indirect light. It is perfect for hanging baskets and indoor pots and is non-toxic to pets, making it a great addition to any indoor plant collection.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water when the top inch of soil is dry.",
            temperature: "Prefers temperatures between 15°C and 25°C.",
            humidity: "Moderate humidity.",
            "Humidity Number": "40-60%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining soil."
        },
        tips: [
            "Trim vines to control growth and promote bushiness.",
            "Mist leaves to maintain humidity.",
            "Fertilize every 2-3 months during the growing season."
        ]
    },
    {
        id: 151,
        plantName: "String of Pearls",
        CommonName: "String of Pearls",
        plantScientificName: "Senecio rowleyanus",
        plantSynonyms: ["Rosary Vine"],
        rating: "4.6/5",
        type: ["hanging_plant", "pet-friendly"],
        note: "Unique pearl-shaped leaves; drought-tolerant.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Trailing vines with pearl-shaped leaves.",
        Description: "String of Pearls is a unique hanging plant with trailing vines adorned with pearl-shaped leaves. It is drought-tolerant and perfect for hanging baskets.",
        longDescription: "Senecio rowleyanus, commonly known as String of Pearls, is a distinctive succulent with trailing vines and pearl-shaped leaves. This plant is drought-tolerant and thrives in bright, indirect light, making it ideal for hanging baskets and indoor pots. String of Pearls is easy to care for and adds an intriguing touch to any plant collection.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water sparingly; allow soil to dry out between waterings.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "Low to moderate humidity.",
            "Humidity Number": "30-50%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining, sandy soil."
        },
        tips: [
            "Handle with care to avoid breaking the delicate leaves.",
            "Fertilize sparingly, once or twice during the growing season.",
            "Provide good air circulation to prevent rot."
        ]
    },
    {
        id: 152,
        plantName: "Burro's Tail",
        CommonName: "Burro's Tail",
        plantScientificName: "Sedum morganianum",
        plantSynonyms: ["Donkey Tail"],
        rating: 4.5,
        type: ["hanging_plant", "pet-friendly"],
        note: "Thick, trailing leaves; drought-tolerant.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Trailing stems with thick, fleshy leaves.",
        Description: "Burro's Tail is a popular hanging plant with trailing stems adorned with thick, fleshy leaves. It is drought-tolerant and perfect for hanging baskets.",
        longDescription: "Sedum morganianum, commonly known as Burro's Tail or Donkey Tail, is a charming succulent with trailing stems and thick, fleshy leaves. This plant is drought-tolerant and thrives in bright, indirect light, making it ideal for hanging baskets and indoor pots. Burro's Tail is easy to care for and adds a lush, cascading effect to any plant collection.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water sparingly; allow soil to dry out between waterings.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "Low to moderate humidity.",
            "Humidity Number": "30-50%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining, sandy soil."
        },
        tips: [
            "Handle with care to avoid breaking the delicate leaves.",
            "Fertilize sparingly, once or twice during the growing season.",
            "Provide good air circulation to prevent rot."
        ]
    },
    {
        id: 153,
        plantName: "Heartleaf Philodendron",
        CommonName: "Heartleaf Philodendron",
        plantScientificName: "Philodendron hederaceum",
        plantSynonyms: ["Sweetheart Plant"],
        rating: 4.7,
        type: ["hanging_plant", "office_plant"],
        note: "Heart-shaped leaves; low maintenance.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Trailing vines with heart-shaped leaves.",
        Description: "Heartleaf Philodendron is a classic hanging plant with trailing vines and heart-shaped leaves. It is low maintenance and perfect for hanging baskets.",
        longDescription: "Philodendron hederaceum, commonly known as Heartleaf Philodendron or Sweetheart Plant, is a beloved houseplant with trailing vines and heart-shaped leaves. This plant thrives in low to bright, indirect light and is easy to care for, making it ideal for hanging baskets and indoor pots. Heartleaf Philodendron is known for its air-purifying qualities and adaptability.",
        careTips: {
            light: "Low to bright, indirect light",
            water: "Keep soil evenly moist, but not waterlogged.",
            temperature: "Prefers temperatures between 18°C and 27°C.",
            humidity: "Moderate humidity.",
            humidityNumber: "40-60%",
            Toxicity: "Toxic to pets if ingested.",
            Soil: "Well-draining, rich soil."
        },
        tips: [
            "Prune regularly to encourage bushier growth.",
            "Wipe leaves to remove dust and promote better photosynthesis.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 154,
        plantName: "String of Hearts",
        CommonName: "String of Hearts",
        plantScientificName: "Ceropegia woodii",
        plantSynonyms: ["Rosary Vine", "Chain of Hearts"],
        rating: "4.6/5",
        type: ["hanging_plant", "pet-friendly"],
        note: "Heart-shaped leaves; easy to propagate.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Trailing vines with heart-shaped leaves.",
        Description: "String of Hearts is a delightful hanging plant with trailing vines adorned with heart-shaped leaves. It is easy to propagate and perfect for hanging baskets.",
        longDescription: "Ceropegia woodii, commonly known as String of Hearts or Rosary Vine, is a charming succulent with trailing vines and heart-shaped leaves. This plant thrives in bright, indirect light and is easy to propagate, making it ideal for hanging baskets and indoor pots. String of Hearts is low maintenance and adds a whimsical touch to any plant collection.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water sparingly; allow soil to dry out between waterings.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "Low to moderate humidity.",
            humidityNumber: "30-50%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining, sandy soil."
        },
        tips: [
            "Handle with care to avoid breaking the delicate leaves.",
            "Fertilize sparingly, once or twice during the growing season.",
            "Provide good air circulation to prevent rot."
        ]
    },
    {
        id: 155,
        plantName: "Lipstick Plant",
        CommonName: "Lipstick Plant",
        plantScientificName: "Aeschynanthus radicans",
        plantSynonyms: ["Lipstick Vine"],
        rating: 4.5,
        type: ["hanging_plant", "office_plant"],
        note: "Bright red flowers; easy to grow.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Glossy leaves with tubular red flowers.",
        Description: "Lipstick Plant is known for its bright red, tubular flowers and glossy leaves. It is easy to grow and perfect for hanging baskets and indoor pots.",
        longDescription: "Aeschynanthus radicans, commonly known as Lipstick Plant or Lipstick Vine, is a vibrant houseplant with glossy leaves and striking red, tubular flowers. This plant thrives in bright, indirect light and high humidity, making it ideal for hanging baskets and indoor pots. Lipstick Plant is low maintenance and adds a pop of color to any plant collection.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil evenly moist, but not waterlogged.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "High humidity.",
            humidityNumber: "50-70%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining, rich soil."
        },
        tips: [
            "Mist regularly to maintain humidity.",
            "Prune to encourage fuller growth and more flowers.",
            "Fertilize monthly during the growing season."
        ]
    },
    {
        id: 156,
        plantName: "Maidenhair Fern",
        CommonName: "Maidenhair Fern",
        plantScientificName: "Adiantum raddianum",
        plantSynonyms: ["Delta Maidenhair"],
        rating: 4.4,
        type: ["hanging_plant", "pet-friendly"],
        note: "Delicate, lacy fronds; loves humidity.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Delicate, lacy fronds.",
        Description: "Maidenhair Fern is a graceful plant with delicate, lacy fronds. It loves humidity and is perfect for hanging baskets and indoor pots.",
        longDescription: "Adiantum raddianum, commonly known as Maidenhair Fern or Delta Maidenhair, is a delicate and graceful plant with lacy fronds. This fern thrives in bright, indirect light and high humidity, making it ideal for hanging baskets and indoor pots. Maidenhair Fern is non-toxic to pets and adds an elegant touch to any plant collection.",
        careTips: {
            light: "Bright, indirect light",
            water: "Keep soil consistently moist, but not waterlogged.",
            temperature: "Prefers temperatures between 15°C and 24°C.",
            humidity: "High humidity.",
            humidityNumber: "50-70%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining, rich soil."
        },
        tips: [
            "Mist regularly to maintain humidity.",
            "Place in a bathroom or kitchen for natural humidity.",
            "Fertilize every 2-3 months during the growing season."
        ]
    },
    {
        id: 157,
        plantName: "String of Dolphins",
        CommonName: "String of Dolphins",
        plantScientificName: "Senecio peregrinus",
        plantSynonyms: ["Dolphin Plant"],
        rating: 4.6,
        type: ["hanging_plant", "pet-friendly"],
        note: "Dolphin-shaped leaves; drought-tolerant.",
        bestFor: ["Hanging baskets", "Indoor pots"],
        highlight: "Trailing vines with dolphin-shaped leaves.",
        Description: "String of Dolphins is a unique hanging plant with trailing vines adorned with dolphin-shaped leaves. It is drought-tolerant and perfect for hanging baskets.",
        longDescription: "Senecio peregrinus, commonly known as String of Dolphins or Dolphin Plant, is a fascinating succulent with trailing vines and dolphin-shaped leaves. This plant is drought-tolerant and thrives in bright, indirect light, making it ideal for hanging baskets and indoor pots. String of Dolphins is easy to care for and adds a whimsical touch to any plant collection.",
        careTips: {
            light: "Bright, indirect light",
            water: "Water sparingly; allow soil to dry out between waterings.",
            temperature: "Prefers temperatures between 18°C and 24°C.",
            humidity: "Low to moderate humidity.",
            humidityNumber: "30-50%",
            Toxicity: "Non-toxic to pets.",
            Soil: "Well-draining, sandy soil."
        },
        tips: [
            "Handle with care to avoid breaking the delicate leaves.",
            "Fertilize sparingly, once or twice during the growing season.",
            "Provide good air circulation to prevent rot."
        ]
    }

];

// console.log(plants);
export default plants;