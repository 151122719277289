import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: 'AIzaSyC-ZUBxNYOqu26b41_3360WA5YUTYrNABs',
  authDomain: 'Plantifo -64886.firebaseapp.com',
  databaseURL: 'https://Plantifo -64886-default-rtdb.firebaseio.com/',
  projectId: 'Plantifo -64886',
  storageBucket: 'Plantifo -64886.appspot.com',
  messagingSenderId: '914657376681',
  appId: '1:914657376681:web:90b51069ba4d2c9117ecb8',
};

const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { app, firestore, storage };
