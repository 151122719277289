import React, { useState, useRef } from "react";
import CardContainer from "./CardContainer";
import NotFound from "../img/NotFound.svg"; // Adjust the path if necessary
import { loader } from "../context/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import PlantContainer from "./PlantContainer";

const RowContainer = ({ data, flag ,filter }) => {
  const rowContainer = useRef();
  // const [visibleItems, setVisibleItems] = useState(6);

   let dispatch = useDispatch();
   let visibleItems = useSelector(state=>state.category.value);

  // const loadMore = () => {
  //   setVisibleItems((prev) => prev + 6);
  // };

  return (
    <div className="w-full mt-12">
     



      {data && data.length > 0 ? (
        <div
          ref={rowContainer}
          className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full justify-items-center ${
            flag ? "overflow-x-scroll scrollbar-none" : "overflow-x-hidden"
          }`}
        >
          {data.slice(0, visibleItems).map((item, id) => (
            <CardContainer item={item} flag={flag} key={id} />
          ))}
        </div>
      ) : (
        <div className="w-full flex flex-col items-center justify-center">
          <img src={NotFound} alt="Not Found" className="h-340" />
          <p className="text-xl text-headingColor font-semibold">
            Item Not Available
          </p>
        </div>
      )}

      {visibleItems < data.length && (
        <div className="flex justify-center mt-8">
          <button
            className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            onClick={()=>dispatch(loader())}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default RowContainer;
