import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WiHumidity } from "react-icons/wi";
import { MdOutlineHeight } from "react-icons/md";
import { FaTemperatureHigh } from "react-icons/fa";
import { TiWeatherSunny } from "react-icons/ti";
import CartContainer from './CartContainer';
import { useStateValue } from "../context/StateProvider";

const PlantContainer = () => {
    const [{ plantItems, cartShow }, dispatch] = useStateValue();
    const location = useLocation();
    const navigate = useNavigate();  // Initialize the useNavigate hook
    const item = location.state?.item;

    // Scroll to top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
           

            <div className="flex flex-col md:flex-row justify-evenly p-4">
                <div className="w-full md:w-1/2 flex justify-center md:justify-end mb-4 md:mb-0">
                    <img
                        src={item?.image}
                        className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl h-auto"
                    />
                </div>
                <div className="w-full md:w-1/2 md:ml-8">
                    <p className="text-3xl md:text-4xl lg:text-5xl font-bold text-green-600 text-center md:text-left">{item?.plantName}</p>
                    <p className="mt-4 text-justify">{item?.longDescription}</p>
                    <p className="mb-4 mt-3"><strong>Scientific Name:</strong> {item?.plantScientificName}</p>
                    <p className="mb-4"><strong>Synonyms:</strong> {item?.Synonyms || item?.commonName}</p>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <p className="flex items-center"><WiHumidity className="text-green-600 w-6 h-6 mr-2" /> <span className="ml-2">{item?.careTips?.humidityNumber}</span></p>
                        <p className="flex items-center"><FaTemperatureHigh className="text-green-600 w-6 h-6 mr-2" /> <small className="ml-2">{item?.careTips?.temperature}</small></p>
                        <p className="flex items-center"><TiWeatherSunny className="text-green-600 w-6 h-6 mr-2" /><span className="ml-2">{item?.careTips?.light}</span> </p>
                        <p className="flex items-center"><MdOutlineHeight className="text-green-600 w-6 h-6 mr-2" /><span className="ml-2">2 feet</span> </p>
                    </div>
                    <p className="mb-4"><strong>Best For:</strong> {item?.bestFor}</p>
                    <p className="mb-4"><strong>Highlight:</strong> {item?.highlight}</p>

                    {item?.careTips && (
                        <div className="mb-4">
                            <h3 className="text-xl font-semibold mb-2 mt-2">Care Tips</h3>
                            <p><strong>Water:</strong> {item.careTips.water}</p>
                            <p className="mt-1"><strong>Toxicity:</strong> {item.careTips.toxicity}</p>
                            <p className="mt-1"><strong>Soil:</strong> {item.careTips.soil}</p>
                            <ul className="list-disc ml-5 mt-1">
                                {item.tips && item.tips.map((tip, index) => (
                                    <li key={index}>{tip}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            {cartShow && <CartContainer />}
             {/* Back Button */}
             <div className="flex justify-center mb-4">
                <button 
                    onClick={() => navigate(-1)} 
                    className="p-2 w-20 bg-green-600 text-white rounded hover:text-black"
                >
                    Back
                </button>
            </div>
        </>
    );
}

export default PlantContainer;
